
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Main from './Main';
import "./index.css";


function App() {


  return (
    <div>
      <BrowserRouter>
      <Main/>
      </BrowserRouter>
    </div>
  );
}

export default App;
