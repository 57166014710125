import React, { useEffect, useState } from 'react'
import vacation_pic from './vacation-pic.png';
import './vacation.css'
import Apiservice from '../../../Api/ApiController';
import Navbar from '../../navbar/NavIgationBar';
import Footer from '../../footer/Footers';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Api_Base_Url} from '../../../Api/ApiController'
export default function VacationMode() {
  const [vacationsData,setvacationsData] = useState([]);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedVacation, setSelectedVacation] = useState(null); // State to store the selected vacation
  const URL = "https://desigro.languagenectar.in/storage/app/public/product/";
  const [startdate,setstartdate] = useState("");
  const [enddate,setenddate] = useState("");
  const [onblur,setonblur] = useState();
  const userId =  sessionStorage.getItem('userID');
  const token = sessionStorage.getItem('authtoken');

  const Vacation = ()=>{
    Apiservice.VacationList().then((response)=>{
               if(response.data.status === true){
                  setvacationsData(response.data.data)
             
               }else{
                      console.error(response);
               }
    }).catch((err)=> console.log(err))
  }

  useEffect(()=>{
    Vacation()
  },[]);
  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); // Adjust for time zone offset
    const formattedDate = adjustedDate.toISOString().slice(0, 10); // Format as "yyyy-MM-dd"
    return formattedDate;
  };

  const formatDate = (dateString) => {
    // Use Date object to format the date
    const date = new Date(dateString);
    // Customize the date formatting as needed
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    return formattedDate;
  };

  const handleEditClick = (vacation) => {
    setSelectedVacation(vacation); 
    setstartdate(formattedDate(vacation.vacation_start));
    setonblur(formattedDate(vacation.vacation_start))
    setenddate(formattedDate(vacation.vacation_end));
    setShowModal(true); 
  };

  
  const UpdateVacation = async (e)=>{
   e.preventDefault()
    const response = await axios.put(Api_Base_Url +'customer/update-vacation',{
      id: parseInt(selectedVacation.id), // Include the id field
      vacation_start:startdate,
      vacation_end:enddate,
      user_id:userId,
    },
    {
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
 
    if(response.data.status === true){
      Swal.fire({
                        icon: 'success',
                        text: response.data.message
                    });
                    setShowModal(false)
                    Vacation()
    }else{
      Swal.fire({
                        icon: 'error',
                        text: response.data.message,
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000
                    });
    }
  }

  const deletevacation  = (vacationID) =>{
    Apiservice.DeleteVacation({
          id:vacationID
    }).then((response)=>{
         if(response.data.status === true){
                 Swal.fire({
                  icon:'success',
                  text:response.data.message
                 })
                 Vacation()
         }else{
            console.error(response);
         }
    }).catch((error)=> console.error(error))
  }
  return (
    <>
    <Navbar/>
    <section>
        <div className='vacation-image'>
            <img src={vacation_pic} alt="" />
        </div>
             
             <section>
             <div className='vacation-list'>
      {vacationsData.map((vacation) => (
        <section>
        <div key={vacation.id} className='vacation-item'>
            <div className='vacation-product-image'>
          <img src={URL + vacation.image} alt="Product"  />
            </div>
          <div className='vacation-details'>
            <h2>{vacation.name}</h2>
            <p><strong>Start Date:</strong> {formatDate(vacation.vacation_start)}</p>
            <p><strong>End Date:</strong> {formatDate(vacation.vacation_end)}</p>
          </div>
          <div className='action-vacation'>
          <button className='vacation-edit' onClick={() => handleEditClick(vacation)}>Edit</button>
          
            <button className='vacation-delete' onClick={()=>deletevacation(vacation.id)}>Delete</button>
        </div>
        </div>

      
        </section>

      ))}
    </div>
    {showModal && selectedVacation && (
        <div className="modal-vacation">
          <div className="modal-content-vacation">
            <span className="close-vacation" onClick={() => setShowModal(false)}>&times;</span>
            <h2>Edit Vacation</h2>
             <div className='form-group'>
              <label htmlFor="">Start Date</label>
                 <input type="date" value={startdate} min={onblur}  onChange={(e)=>setstartdate(e.target.value)} />
             </div>
             <div className='form-group'>
              <label htmlFor="">End Date</label>
                 <input type="date" value={enddate} min={startdate} onChange={(e)=>setenddate(e.target.value)} />
             </div>
             <div className="modal-button-vacation">
             <button onClick={UpdateVacation}>Update</button>
             </div>
          </div>
        </div>
      )}
   
             </section>
    </section>

      <Footer/>
    </>
  )
}
