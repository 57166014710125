import React from 'react'
import Navbar from '../../navbar/NavIgationBar'
import './chooseus.css';
import Footer from '../../footer/Footers'
export default function ChooseUs() {
  return (
    <div>
        <div>
            <Navbar/>
      <section className='chooseus-main'>
        {/* <div className='chooseus-main'> 
        <div className='chooseus-head'>
             <h2>WHY CHOOSE US</h2>
            <hr />
        </div>
        <div className='why-chooseus-list'>
            <ul>
                <li>The Desi Milk™ an Authentic A2 Milk, produce of Indigenous & Healthy Gir Cow, Buffalo & Goat.</li>
                <li>Far away from city pollution, Whole, Pure and Fresh milk straight from the farm.</li>
                <li>Whole milk, Un-adulterated, Un-Pasteurized, Un-Toned & Non-Homogenized.</li>
                <li>Zero preservatives & Harmones, Milk is stored at a constant temperature of 3 °C to maintain it's Freshness & Natural Properties.</li>
                <li>Full of Nutrients, contains Omega 3 Fatty Acids, Essential Vitamins such as A, D, B12 - are essential for mental growth.</li>
                <li>Full of Minerals such as Calcium, Phosphorus & Potassium - are essential for strong bones & nervous system.</li>
                <li>A2 Milk is a source of Complete Protein and is one of the Most Affordable Protein sources available in the market.</li>
                <li>Milk is handled with Great Hygiene throughout the process, as per Safe & Nutritious Food Handbook for Dairy Sector.</li>
                <li>Registered with Food and Safety Standard Authority of India (fssai).</li>
            </ul>
        </div>
        </div> */}

        <img src={process.env.PUBLIC_URL + '/assets/img/Whychooseus.png'} alt="" />
      </section>
        </div>
        <Footer/>
    </div>
  )
}
