
import React, { useEffect, useState } from "react";
import "./shopall.css";
import axios from "axios";
import NavigationBar from "../../navbar/NavIgationBar";
import Footers from "../../footer/Footers";
import { Link, useNavigate } from "react-router-dom";
import Apiservice from "../../../Api/ApiController";

export default function ShopAll() {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const fetchData = async () => {
    try {
     
      const response =  await Apiservice.Shopall({
           
            limit: 10,
            offset: 0,
   
      })
      if(response.data.status === true){
        setProducts(response.data.data.products);

      }else{

        console.error(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const HandleProductClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };

  const HandleSubscribeProduct = (productId) => {
    navigate(`/subscribeproduct-details/${productId}`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  // Filter the products to get products for the current page
  const productsOnCurrentPage = products.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(products.length / ITEMS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <NavigationBar />
      <div>
        <div>
          <h3 className="page-title-shop">SHOP ALL</h3>
        </div>

        <div className="products-list-shop">
          {productsOnCurrentPage.map((product) => (
            <div
              key={product.id}
              className="product-card-shop"
              
            >
              <div className="product-image-shop">
                <img
                  src={`https://desigro.languagenectar.in/storage/app/public/product/${product.image}`}
                  alt="Product"
                />
              </div>
              <div className="product-details-shop">
                <h5 className="product-name-shop">{product.name}</h5>
                <div className="product-price-shop">
                  <s>₹{product.price}</s>
                  {product.discount_type === 'percent' ? (
                    <>
                    <p>From ₹{product.price - (product.price * (product.discount/100))}</p>
                    </>
                  ) : (
                    <>
                          <p>From ₹{product.price - product.discount}</p>
                    </>
                  )}
                </div>
              </div>
              <div className="product-actions-shop">
                <button  onClick={() => HandleProductClick(product.id)}>Buy Once</button>
                {product.category_ids && product.category_ids.is_subscribe === 1 && (
                  <button
                    className="subscribe-btn-shop"
                    onClick={() => HandleSubscribeProduct(product.id)}
                  >
                    Subscribe
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>

        {totalPages > 1 && (
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <span
                key={page}
                className={currentPage === page ? "active" : ""}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </span>
            ))}
          </div>
        )}
      </div>
      <Footers />
    </div>
  );
}
