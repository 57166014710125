import React from 'react';
import './contactus.css'; // Assuming you have a corresponding CSS file
import NavIgationBar from '../../navbar/NavIgationBar';
import Footers from '../../footer/Footers';

export default function ContactUs() {
  return (
    <div>
      <NavIgationBar/>
        <section>
    <div className="contact-us-container">
      <div className="form-main">
        <h1>Contact Us</h1>
        <form className="form">
            <div className='row'>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" placeholder="Your Name" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" placeholder="Your Email" required />
          </div>
            </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input type="tel" id="phone" name="phone" placeholder="Your Phone Number" />
          </div>
          <div className="form-group">
            <label htmlFor="comment">Comment</label>
            <textarea id="comment" name="comment" placeholder="Your Comment" rows="4"></textarea>
          </div>
          <div className="form-group">
            <button className='btn-contactus' type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
   <Footers/>
        </section>
    </div>
  );
}

