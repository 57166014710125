// PrivateRoute.js
import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../../Authentication/Authcontext';

const PrivateRoute = ({ children }) => {
  const { token } = useAuth();

  return token ? (
    <>{children}</>
  ) : (
    <Navigate to="/signup" />
  );
};

export default PrivateRoute;
