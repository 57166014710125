import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import './locationrequest.css'
import GoogleMaps from '../../GoogleMap'
import Apiservice from '../../../Api/ApiController';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
export default function LocationRequest() {
    
    const [currentLocation, setCurrentLocation] = useState(null);
    const [formattedAddress, setFormattedAddress] = useState('');
    const userId  = localStorage.getItem('userId')
    const [name,setname] =  useState('');
    const [mobilenumber,setmobilenumber] = useState('');
    const [latitude,setlatitude] = useState('');
 const [longitude,setlongitude] = useState('');
 const [autocomplete, setAutocomplete] = useState(null);
 const [currentAddress, setCurrentAddress] = useState("");
 const apiKey = 'AIzaSyB-dfXTj2hUMYzTJjm7cURsllyFm1W3tBM';
    const navigate = useNavigate()
      const SubmitLocation = (e)=>{
        const numericMobileNumber = mobilenumber.replace(/\D/g, ''); // Remove non-numeric characters
        console.log(numericMobileNumber.length);
        
        e.preventDefault();
        if (numericMobileNumber.length < 12) {
          Swal.fire({
            icon: 'error',
            text: "Enter a 10-digit Mobile Number",
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000
          });
          return; // Stop further execution
        }
          
        // if (mobilenumber.length === 10) {
      Apiservice.Addlocationrequest({
        user_id:userId,
        name:name,
        city:formattedAddress,
        phone:mobilenumber
      }).then((response)=>{
               if(response.data.status === true){
                  Swal.fire({
                    icon:'success',
                    text:response.data.message
                  })
                  navigate('/locationadded')
               }else{
                Swal.fire({
                    icon:'error',
                    text:response.data.message
                  })
               }
      }).catch((err)=> console.log(err))
    // }
      }
        useEffect(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            async  (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentLocation({ lat: latitude, lng: longitude });

            await fetchFormattedAddress(latitude, longitude);
          },
          (error) => {
            console.error('Error getting user location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    }, []);
    const fetchFormattedAddress = async (lat, lng) => {
      
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    
        try {
          const response = await fetch(apiUrl);
          const data = await response.json();
          if (data.results && data.results.length > 0) {
            setFormattedAddress(data.results[0].formatted_address);
          }
        } catch (error) {
          console.error('Error fetching formatted address:', error);
        }
      };

      useEffect(() => {
      
   
        const loadGoogleMaps = () => {
          if (!window.google) {
           
         
              const script = document.createElement('script');
              script.type = 'text/javascript';
              script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
              script.onload = () => {
                  ('Google Maps script loaded');
                  initAutocomplete();
              };
              script.onerror = (error) => {
                  console.error('Error loading Google Maps script:', error);
              };
              document.head.appendChild(script);
          } else {
              initAutocomplete();
          }
      };
      
    
        const initAutocomplete = () => {
            const google = window.google;
            const newAutocomplete = new google.maps.places.Autocomplete(document.getElementById('search-input'));
    
            newAutocomplete.addListener('place_changed', () => {
                const place = newAutocomplete.getPlace();
                if (!place.geometry) {
                    console.error("No details available for input: '" + place.name + "'");
                    return;
                }
                const latitude = place.geometry.location.lat();
                setlatitude(latitude)
                const longitude = place.geometry.location.lng();
                setlongitude(longitude)
                const formattedAddress = place.formatted_address;
                setFormattedAddress(formattedAddress)
                setCurrentLocation({ lat: latitude, lng: longitude })
                // setCurrentAddress(prevAddress => formattedAddress); 
                
                const locationData = {currentAddress,latitude,longitude};
                sessionStorage.setItem('location', JSON.stringify(locationData));
            });
    
            setAutocomplete(newAutocomplete);
            
        };
    
        loadGoogleMaps();
    }, []);
  return (
      <>
      <section className='form-locationrequest-section-main'>
           
       <section className='form-locationrequest-section'>
           <form className='form-locationrequest' onSubmit={SubmitLocation}>
           <div className='form-locationrequest-div-1' >
                <h4>Location Request</h4>
                <p>Please Provide this Information so our team can reach you</p>
            </div>
            <div className="form-group">
                <label htmlFor="">Enter Mobile Number</label>
                <InputMask
      mask="+\9\19999999999"
      placeholder="Enter phone number"
 value={mobilenumber}
 onChange={(e)=>setmobilenumber(e.target.value)}
      className="form-input5"
      />
            </div>
            <div className="form-group">
                <label htmlFor="name">Name</label>
                <input 
                type="text"
                 id='name'
                  placeholder='Name(optional)'
                  value={name}
                  onChange={(e)=>setname(e.target.value)}
                className="form-input5" />
            </div>
            <section className='location-request-map'>
      {currentLocation && <GoogleMaps center={currentLocation} />}
      </section>
            <div className="form-group">
                <label htmlFor="search-input">Location</label>
                <input type="text"   id="search-input" placeholder='Your location' value={formattedAddress} onChange={(e)=>setFormattedAddress(e.target.value)} className="form-input5" />
            </div>

            <div className='locationrequest-button'>
                <button type='submit' >Request Location</button>
            </div>
           </form>
       </section>
      </section>
     
     </>
  )
}
