import React, { useEffect, useState } from "react";
import Apiservice from "../../Api/ApiController";
import { useNavigate } from "react-router-dom";

const BestSellingProduct = () => {
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const navigate = useNavigate();

  const fetchTopSellingProducts = async () => {
    try {
      const response = await Apiservice.Shopall({
        limit: 10,
        offset: 0,
      });
      if (response.data.status === true) {
        setTopSellingProducts(response.data.data.products);
      } else {
        console.error("Error in response:", response.data);
      }
    } catch (error) {
      console.error("Error fetching top-selling products:", error);
    }
  };

  const handleProductClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };

  const handleSubscribeClick = (productId) => {
    navigate(`/subscribeproduct-details/${productId}`);
  };

  useEffect(() => {
    fetchTopSellingProducts();
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
      {topSellingProducts.map((product) => (
        <div
          key={product.id}
          className="bg-white shadow-lg rounded-lg overflow-hidden  py-4 hover:scale-105 transition-all duration-300 ease-in-out "
        >
          <div className="h-44 flex items-center justify-center ">
            <img
              src={`https://desigro.languagenectar.in/storage/app/public/product/${product.image}`}
              alt={product.name}
              className="h-full object-contain"
            />
          </div>

          <div className="mt-4">
            <img
              src="https://desigro.languagenectar.in/storage/app/public/business/2023-11-22-655dc1aee9d9d.png"
              className="w-20"
            />
          </div>

          <section className="px-2 flex justify-between items-center">
            <div>
              <h5 className="text-lg font-semibold truncate">{product.name}</h5>
              <span className="text-sm flex items-center gap-2 ">
                <img
                  src="https://imgs.search.brave.com/7vcRVrkqQlIPgVkP4uBHxXY3HcS0dAKY36izgyIOvBc/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy83/Lzc4L0luZGlhbi12/ZWdldGFyaWFuLW1h/cmsuc3Zn"
                  className="h-4 "
                />
                {product.size}
              </span>
            </div>

            <div className="text-right">
              <s className="text-red-500">₹{product.price}</s>
              <br />
              <span className=" text-green-600">
                From ₹
                {product.discount_type === "percent"
                  ? (
                      product.price -
                      product.price * (product.discount / 100)
                    ).toFixed(2)
                  : (product.price - product.discount).toFixed(2)}
              </span>
            </div>
          </section>

          <hr className="my-3 mx-2" />

          <div className="flex justify-between items-center my-2 px-2">
            <button
              className=" text-black px-4 py-2 rounded-lg border-1 transition-colors active:scale-95"
              onClick={() => handleProductClick(product.id)}
            >
              + Buy Once
            </button>
            {product.category_ids &&
              product.category_ids.is_subscribe === 1 && (
                <button
                  className="bg-[#6ea63e] text-white px-4 py-2 rounded-lg hover:bg-[#4e762c] transition-colors active:scale-95"
                  onClick={() => handleSubscribeClick(product.id)}
                >
                  Subscribe
                </button>
              )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BestSellingProduct;
