import React from 'react';
import { useNavigate } from 'react-router-dom';
const styles = {
    container_locationadded: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    section_locationadded: {
      textAlign: 'center',
    },
    redText: {
      color: 'red',
    },
    greenText: {
      color: 'green',
      cursor: 'pointer'
    },
  };
export default function Locationaddedpage() {

    const navigate = useNavigate();
     
    const productpagenavigation = ()=>{
        navigate('/')
    }
  return (
    <>
      <section>
      <div style={styles.container_locationadded}>
      <section style={styles.section_locationadded}>
        <div style={styles.redText}>
          Thank you for the information
        </div>
        <div>
          We will notify you when we are available in your area
        </div>
        <div style={styles.greenText} onClick={productpagenavigation}>
          Explore our products in other areas <i className='bx bx-right-arrow-alt'></i>
        </div>
      </section>
    </div>
      </section>
    </>
  );
}
