import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from '../../navbar/NavIgationBar';
import Footers from '../../footer/Footers';
import Apiservice from '../../../Api/ApiController';
import './orderhistory.css'
export default function Orderhistory() {
    const token = sessionStorage.getItem("authtoken");
    const navigate = useNavigate();
    const [OrderList, setOrderList] = useState([]);


    const fetchorderlist = async () => {
        try {
     
           const response =  await Apiservice.orderList({
                   limit: 10,
                offset: 0,
           })
          if(response.data.status ===  true){
            setOrderList(response.data.data.orders);
            console.log(response.data)
          }
        } catch (error) {
          console.log("Error in fetching Api", error);
        }
      };
      useEffect(() => {
        fetchorderlist();
      }, []);

      const formatDate = (dateString) => {
        // Use Date object to format the date
        const date = new Date(dateString);
        // Customize the date formatting as needed
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return formattedDate;
      };
      const [currentPage, setCurrentPage] = useState(1);
      const ITEMS_PER_PAGE = 4;
      // Calculate the index range for the current page
      const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
    
      // Filter the orderList to get orders for the current page where is_subscribe is 0
      const ordersOnCurrentPage = OrderList.filter(order => order.is_subscribe === 0).slice(startIndex, endIndex);
    
      // Calculate the total number of pages based on filtered orders
      const totalPages = Math.ceil(OrderList.filter(order => order.is_subscribe === 0).length / ITEMS_PER_PAGE);
    
      // Function to handle page changes
      const handlePageChange = (page) => {
        setCurrentPage(page);
      };
      const orderdetials = (orderId)=>{
        navigate(`/orderdetails/${orderId}`)
      }
  return (
   <>
   <Navbar/>
   <section className='order-history-section'>
   <div className='order-history-container1'>
      <h1>Order History</h1>
     
      {/* {Array.isArray(OrderList) && ordersOnCurrentPage.length > 0 ? (
  ordersOnCurrentPage
     // Filter orders where is_subscribe is 0
    .map((order, index) => ( */}
    {OrderList.map((order,index)=>(
      <div className='orderhistory-list'>
      <div key={index} className='order-card' onClick={()=>orderdetials(order.id)}>
        <div>
        <p>Order ID : {order.id}</p>
        <p className='order-date'>Order-Date : {formatDate(order.schedule_at)}</p>
        </div>
        <p>Address: {order.delivery_address.address.address}</p>
        <p>Total amount: {order.order_amount}</p>
    
      </div>
      <div className='order-history-view-details'>
      <small style={{ color: order.payment_status === 'paid' ? 'green' : 'red', textTransform: 'uppercase' }}>
        Payment Status :  {order.payment_status}
        </small>
        <p onClick={()=>orderdetials(order.id)}>
        view details
        </p>
      </div>
      </div>
    ))}



      {totalPages > 1 && (
        <div className='pagination'>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <span
              key={page}
              className={currentPage === page ? 'active' : ''}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </span>
          ))}
        </div>
      )}
    </div>
   </section>
   <Footers/>
   </>
  )
}
