import React from "react";

const Banner = () => {
  const items = [
    {
      title: "Trained Organic Farmers",
      image:
        "https://a2organicmilk.co.in/wp-content/uploads/2024/09/Group1.png",
    },
    {
      title: "Chemical-free soil and Organic Fodder",
      image:
        "https://a2organicmilk.co.in/wp-content/uploads/2024/09/Group2.png",
    },
    {
      title: "Use of organic manure",
      image:
        "https://a2organicmilk.co.in/wp-content/uploads/2024/09/Group3.png",
    },
    {
      title: "Specially-curated balanced diets for cows",
      image:
        "https://a2organicmilk.co.in/wp-content/uploads/2024/09/Group9.png",
    },
    {
      title: "Contactless milking and packaging processes & distribution",
      image:
        "https://a2organicmilk.co.in/wp-content/uploads/2024/09/Group4.png",
    },
    {
      title: "Chilled to 4° celsius upon milking",
      image:
        "https://a2organicmilk.co.in/wp-content/uploads/2024/09/Group5.png",
    },
    {
      title: "Free from Chemicals and Adulteration",
      image:
        "https://a2organicmilk.co.in/wp-content/uploads/2024/09/Group-18.png",
    },
    {
      title: "Delivered Fresh Everyday",
      image:
        "https://a2organicmilk.co.in/wp-content/uploads/2024/09/Group6.png",
    },

  ];

  return (
    // <div className="w-full py-8 px-20 flex items-center justify-center bg-red-200 ">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-[80%] mx-auto px-4 ">
        {items.map((item, index) => (
          <div
            key={index}
            className="banner-item bg-white  flex flex-col items-center p-2 border-1"
          >
            <img
              src={item.image}
              alt={item.title}
              className="banner-image w-30 h-30 object-contain mb-4"
            />
            <h3 className="banner-title text-center text-sm font-semibold text-gray-800">
              {item.title}
            </h3>
          </div>
        ))}
      </div>
    // </div>
  );
};

export default Banner;
