import React from "react";
import { Link } from "react-router-dom";
import NavIgationBar from "../../navbar/NavIgationBar"
import Footers from "../../footer/Footers";
const ResultPage = () => {


  return (
    <>
      <NavIgationBar />
      <div className="w-full p-4">
        <h1 className="text-2xl font-semibold mb-6 text-center text-[#2f8814]">
          Deliver History
        </h1>
        <div className="overflow-x-auto">
          <table className="min-w-full text-left text-sm text-gray-500">
            <thead className="bg-[#72b45e] text-xs uppercase text-white">
              <tr>
                <th className="px-4 py-2 border-b">S.No</th>
                <th className="px-4 py-2 border-b">Date</th>
                <th className="px-4 py-2 border-b">Product Name</th>
                <th className="px-4 py-2 border-b">Unit</th>
                <th className="px-4 py-2 border-b">Size</th>
                <th className="px-4 py-2 border-b">Unit Price</th>
                <th className="px-4 py-2 border-b">Delivery Value</th>
                <th className="px-4 py-2 border-b">Wallet Opening</th>
                <th className="px-4 py-2 border-b">Wallet Closing</th>
                <th className="px-4 py-2 border-b">Bottle Received</th>
                <th className="px-4 py-2 border-b">Bottle Balance</th>
                <th className="px-4 py-2 border-b">Add Grievance</th>
                <th className="px-4 py-2 border-b">Grievance Status</th>
                <th className="px-4 py-2 border-b">Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-2 border-b">1</td>
                <td className="px-4 py-2 border-b">2024-11-22</td>
                <td className="px-4 py-2 border-b">Milk</td>
                <td className="px-4 py-2 border-b">Litre</td>
                <td className="px-4 py-2 border-b">1L</td>
                <td className="px-4 py-2 border-b">$2.5</td>
                <td className="px-4 py-2 border-b">$50</td>
                <td className="px-4 py-2 border-b">$100</td>
                <td className="px-4 py-2 border-b">$50</td>
                <td className="px-4 py-2 border-b">2</td>
                <td className="px-4 py-2 border-b">3</td>
                <td className="px-4 py-2 border-b">--</td>
                <td className="px-4 py-2 border-b">Resolved</td>
                <td className="px-4 py-2 border-b text-red-500">Not Delivered</td>
              </tr>
              <tr>
                <td className="px-4 py-2 border-b">1</td>
                <td className="px-4 py-2 border-b">2024-11-22</td>
                <td className="px-4 py-2 border-b">Milk</td>
                <td className="px-4 py-2 border-b">Litre</td>
                <td className="px-4 py-2 border-b">1L</td>
                <td className="px-4 py-2 border-b">$2.5</td>
                <td className="px-4 py-2 border-b">$50</td>
                <td className="px-4 py-2 border-b">$100</td>
                <td className="px-4 py-2 border-b">$50</td>
                <td className="px-4 py-2 border-b">2</td>
                <td className="px-4 py-2 border-b">3</td>
                <td className="px-4 py-2 border-b">--</td>
                <td className="px-4 py-2 border-b">Resolved</td>
                <td className="px-4 py-2 border-b text-red-500">Not Delivered</td>
              </tr>
             
            </tbody>
          </table>
        </div>
      </div>
      <Footers />
    </>
  );
};

export default ResultPage;
