import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./address.css";
import NavIgationBar from "../../../navbar/NavIgationBar";
import Footers from "../../../footer/Footers";
import axios from "axios";
import Apiservice from "../../../../Api/ApiController";

export default function Address() {
  const [profile, setProfile] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedRegionId, setSelectedRegionId] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [cities, setCities] = useState([]);
  const [name, setName] = useState("");
  const [addressType, setAddressType] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [landmark,setlandmark] = useState('');
  const [Pincode,setPincode] = useState('');
  const [floor,setfloor] = useState('');
  const [ flatnumber,setflatnumber] = useState('')
  const token = sessionStorage.getItem("authtoken");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
     
        const response =  await Apiservice.getProfile()
        if(response.data.status === true){
          setProfile(response.data);
        }
        
       
      } catch (error) {
        console.error("Error fetching profile:", error.message);
      }
    };

    fetchProfile();
    fetchRegions();
    fetchAddressList();
  }, []);

  const fetchRegions = async () => {
    try {

         const response = await Apiservice.ZoneList()
      if (
        response.data &&
        response.data.status &&
        Array.isArray(response.data.data)
      ) {
        setRegions(response.data.data);
      } else {
        console.error("Invalid API response format");
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  const fetchCities = async (regionId) => {
    console.log(regionId)
    try {
     
      const response =  await Apiservice.citylist(
       regionId
      )
      if (response.data.status === true) {
   
        setCities(response.data.data);
      } else {
        console.error("Invalid API response format");
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleRegionChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setSelectedRegionId(selectedId);
    fetchCities(selectedId);
  };

  const handlecityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCityName = event.target.options[event.target.selectedIndex].text;
    
    setSelectedCityId(selectedCityId)
    setLocation(selectedCityName); // Set the location to the selected city name
  }

  const handleAddAddress = async () => {
    try {
      if (token && profile) {
      
               const response = await Apiservice.AddressAdd({
                contact_person_name:name,
                address_type:addressType,
                contact_person_number:mobileNumber,
                address:address,
                region_id:selectedRegionId,
                city_id:selectedCityId,
                location:location,
                pin_code:Pincode,
                floor:floor,
                flat_no:flatnumber,
                land_mark:landmark,

               })
             
        if ( response.data.status ===  true) {
         
          clearFormFields();
          setIsModalOpen(false);
          fetchAddressList();
          window.location.reload()
        } else {
          console.error(
            "Failed to add address:",
            response.data 
          );
        }
      } else {
        console.error("User not authenticated or profile data not available");
      }
    } catch (error) {
      console.error("Error adding address:", error);
    }
  };

  const handleUpdateAddress = async () => {
    try {
      if (token && profile && selectedAddress) {
    
  const response =  await Apiservice.AddressAdd({
    
    address_type:addressType,
    land_mark:landmark,
    address:address,
    region_id:selectedRegionId,
    city_id:selectedCityId,
    location:location,
    id: selectedAddress.id,
    pin_code:Pincode,
    floor:floor,
    flat_no:flatnumber
  })
        if (response.data && response.data.status ===  true) {
          
          clearFormFields();
          setIsModalOpen(false);
          fetchAddressList();
        } else {
          console.error(
            "Failed to update address:",
            response.data.message || "Unknown error"
          );
        }
      } else {
        console.error(
          "User not authenticated, profile data not available, or no address selected"
        );
      }
    } catch (error) {
      console.error("Error updating address:", error);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (selectedAddress) {
      handleUpdateAddress();
    } else {
      handleAddAddress();
    }
  };



  const clearFormFields = () => {
    setName("");
    setAddressType("");
    setMobileNumber("");
    setAddress("");
    setSelectedRegionId(null);
    // Clear other fields as needed
  };

  const fetchAddressList = async () => {
    try {
     
      const response = await Apiservice.FetchAddresslist()
      if (response.data.status === true) {
  
        setAddressList(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClick = (address) => {
    setSelectedAddress(address);
    setPincode(address.pin_code);
    setlandmark(address.land_mark);
    setflatnumber(address.flat_no);
    setAddress(address.address);
    setSelectedRegionId(address.region_id);
    fetchCities(address.city_id);
    setLocation(address.location);
    setfloor(address.floor)

    setIsModalOpen(true);
  };

  const handleDeleteClick = async (addressId) => {
    try {
   
      const response = await Apiservice.DeleteAddress({
        address_id:addressId
      })
      if (response.data.status === true) {
        console.log("Address deleted successfully!");
        fetchAddressList();
      } else {
        console.error(
          "Failed to delete address:",
          response.data.message || "Unknown error"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <NavIgationBar />
      <section className="address-main">
        <div className="address-div1">
          <h1>Addresses</h1>
        </div>
        <div className="address-div2">
          <Link to="/profile" style={{ color: "black" }}>
            Return to Account details
          </Link>
        </div>
        <div className="address-div3">
        <button
  type="button"
  data-bs-toggle="modal"
  data-bs-target="#exampleModal"
  onClick={() => {
    clearFormFields();  // Clear the form fields
    setIsModalOpen(true);
    setSelectedAddress(null);
  }}
>
  Add a new address
</button>
          <div
            className={`modal fade ${isModalOpen ? "show" : ""}`}
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: isModalOpen ? "block" : "none" }}
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {selectedAddress ? "Edit Address" : "Add a New Address"}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setIsModalOpen(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <form
                    action="#"
                    onSubmit={handleFormSubmit}
                    className="needs-validation"
                    noValidate
                  >

<div className="mb-3">
                      <label htmlFor="regionDropdown">Select Region:</label>
                      <select
                        id="regionDropdown"
                        onChange={handleRegionChange}
                        value={selectedRegionId || ""}
                        className="form-select"
                      >
                        <option value="" disabled>
                          Select a region
                        </option>

                        {regions.map((region) => (
                          <option key={region.id} value={region.id}>
                            {region.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="cityDropdown">Select City:</label>
                      <select id="cityDropdown" onChange={handlecityChange} className="form-select">
                      <option value="" selected disabled>
                          Select a location
                        </option>
                        {cities.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        Please enter your location.
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="addressType" className="form-label">
                        Address Type
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="addressType"
                        value={addressType}
                        onChange={(e) => setAddressType(e.target.value)}
                        placeholder="e.g., Home, Work"
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter the address type.
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                      Flat Number/Office Unit
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={flatnumber}
                        onChange={(e) => setflatnumber(e.target.value)}
                        placeholder="Flat Number/Office Unit"
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter your flat number.
                      </div>
                    </div>

                   

                    <div className="mb-3">
                      <label htmlFor="Floor" className="form-label">
                        Floor
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="Floor"
                        value={floor}
                        onChange={(e) => setfloor(e.target.value)}
                        placeholder="Floor"
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter a valid mobile number.
                      </div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="address" className="form-label">
                        Address
                      </label>
                      <textarea
                        className="form-control"
                        id="address"
                        placeholder="Enter your address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        rows="3"
                        required
                      ></textarea>
                      <div className="invalid-feedback">
                        Please enter your address.
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Landmark" className="form-label">
                        Landmark
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="Landmark"
                        value={landmark}
                        onChange={(e) => setlandmark(e.target.value)}
                        placeholder="Landmark"
                        required
                      />
                      
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Pincode" className="form-label">
                        Pincode*
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="Pincode"
                        value={Pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        placeholder="Pincode"
                        required
                      />
                      
                    </div>
                    {/* <div className="mb-3">
                      <label htmlFor="address" className="form-label">
                        Location
                      </label>
                      <input
                        className="form-control"
                        id="location"
                        placeholder="Enter your location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        rows="3"
                        required
                      ></input>
                      <div className="invalid-feedback">
                        Please enter your location.
                      </div>
                    </div> */}

                   

                    <button type="submit" className="btn btn-primary">
                      Save Address
                    </button>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="address-div4">
          {addressList.map((address, index) => (
            <div className="address-cards" key={index}>
              <h3>{address.address_type}</h3>
              <p>{address.contact_person_name}</p>
              <p>{address.address}</p>
              <p>{address.location}</p>
              <div className="address-div5">
                <div className="address-div6">
                  <button
                    className="btn btn-outline-success"
                    onClick={() => handleEditClick(address)}
                  >
                    Edit
                  </button>

                  <button
                    className="btn btn-outline-danger "
                    onClick={() => handleDeleteClick(address.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <Footers />
    </div>
  );
}
