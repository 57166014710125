import axios from "axios";

export const  Api_Base_Url = 'https://desigro.languagenectar.in/api/v1/';

const token = sessionStorage.getItem('authtoken')
const Api = axios.create({
    baseURL: Api_Base_Url,
    headers: {
        'Content-Type': 'multipart/form-data', 
        'Accept': 'application/json',
        Authorization:`Bearer ${token}`,
        zoneId : 1,
      },
});

const Apiservice ={
    signUp: (userData) => Api.post('auth/register', userData),
    verifyOtp : (userdata) => Api.post('auth/verify-phone',userdata),
    updateProfile  : (userData) => Api.post('customer/update-profile',userData),
    getProfile : () => Api.get('customer/info'),
    orderList : (params) => Api.get('customer/order/list',{params}),
    ZoneList : ()  => Api.get('services/zone/list'),
    citylist : (regionID) => Api.get(`services/zone/city/${regionID}`),
    AddressAdd : (addressdata) => Api.post('customer/address/add',addressdata),
    FetchAddresslist : () => Api.get('customer/address/list'),
    DeleteAddress : (params) => Api.delete('customer/address/delete',{params}),
    WalletRecharge : (data) => Api.post('customer/order/add-subscription',data),
    cartdetails : () => Api.get('customer/order/cart'),
    Placeorder : (orderData) => Api.post('customer/order/place',orderData),
    RemovecartItem : (params) => Api.delete('customer/order/remove',{params}),
    updatecartItem : (cartData)  => Api.put('customer/order/update',cartData),
    Category : () => Api.get('categories'),
    ContactUs : (contactusData)  => Api.post('contact-us',contactusData),
    Orderdetails : (params) => Api.get('customer/order/details',{params}),
    CouponList : (params) => Api.get('coupon/list',{params}),
    CouponApply : (params) => Api.get('coupon/apply',{params}),
    Config : () => Api.get('config'),
    Shopall : (params) => Api.get('services/all',{params}),
    Addtocart : (cartData) => Api.post('customer/order/add-to-cart',cartData),
    WalletHistory : (params)  => Api.get('customer/wallet/history',{params}),
    notification : (params) => Api.get('customer/notifications',{params}),
    ClearNotification : () => Api.delete('customer/delete-notifications'),
    Addlocationrequest : (locationData) => Api.post('/customer/add-request',locationData),
    SubscriptionList : () => Api.get('customer/order/get-subscription'),
    VacationList    : () => Api.get('customer/get-vacation'),
    updateVacation : (vacationData) => Api.put('customer/update-vacation',vacationData),
    DeleteVacation : (params) => Api.delete('customer/delete-vacation',{params}),
    Search : (params) => Api.get('services/search',{params})
   
} 

export default Apiservice;