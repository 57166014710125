import React, { useState } from 'react';
import './addprofile.css';
// import placeholderImage from './placeholder.png'; // Import your placeholder image
import placeholderimage from './userprofile.jpeg'
import axios from 'axios';
import Apiservice from '../../../../Api/ApiController';
import Swal from 'sweetalert2';
import { NavLink, useNavigate } from 'react-router-dom';
export default function AddProfile() {
  const [profilePic, setProfilePic] = useState(null);
  const [firstname,setfirstname] = useState('');
  const [lastname,setlastname] = useState('');
  const [email,setemail] = useState('');
  const token = sessionStorage.getItem('authtoken');
  const navigate =  useNavigate()
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const AddProfile = async (e)=>{
    e.preventDefault()
    try{
    
      const response =  await Apiservice.updateProfile({
         f_name:firstname,
        l_name:lastname,
        email:email,
        image:profilePic
      })
      if(response.data.status === true){
        Swal.fire({
          icon:'success',
          text:'Profile updated Sccessfully'
        })
        navigate('/regionselect')
      }
    }catch(error){
      console.log(error)
    }
  }

  return (
    <div className="form-container">
    <section>
      <form className="form-addprofile" onSubmit={AddProfile} >
        {/* <label htmlFor="profile-image" className="profile-pic-preview-label">
          <div className="profile-pic-preview">
            <img src={profilePic || placeholderimage} alt="Profile Preview" />
          </div>
        </label>
  
        <input
          id="profile-image"
          type="file"
          className="input-addprofile visually-hidden"
          accept="image/*"
          onChange={handleFileChange}
        />
   */}
        <p className="title-addprofile">Enter Your Details</p>
        <p className="message-addprofile">Signup now and get full access to our app.</p>
        <div className="flex-addprofile">
          <label>
            <input required placeholder="Enter Firstname" value={firstname} onChange={(e)=>setfirstname(e.target.value)} type="text"  className="input-addprofile" />
            {/* <span>Firstname</span> */}
          </label>
  
          <label>
            <input required placeholder="Enter Lastname" value={lastname} onChange={(e)=>setlastname(e.target.value)} type="text" className="input-addprofile" />
            {/* <span className="span-addprofile">Lastname</span> */}
          </label>
        </div>
  
        <label>
          <input required placeholder="Enter Email" value={email} onChange={(e)=>setemail(e.target.value)} type="email" className="input-addprofile" />
          {/* <span>Email</span> */}
        </label>
  
        
        <label>
          <input  placeholder="Enter Referral  Code(Optional)" type="text" className="input-addprofile" />
          {/* <span>Confirm password</span> */}
        </label>
  
        <button type='submit' className="submit-addprofile">Submit</button>
      </form>
      <hr />
      <div className='skipthisstep'>
        <NavLink to="/">
        Skip this Step
        </NavLink>
      </div>
    </section>
  </div>
  
  );
}
