import React, { useEffect, useState } from 'react';
import './Orderdetails.css'; // Import your CSS file for styling
import Navbar from '../../navbar/NavIgationBar';
import Footers from '../../footer/Footers';
import Apiservice from '../../../Api/ApiController';
import { useNavigate, useParams } from 'react-router-dom';

export default function OrderDetails() {
  const [orderdetail,setorderdetail] = useState([]);
  const orderID = useParams();
  const URL =  "https://desigro.languagenectar.in/storage/app/public/product/";
  const navigate = useNavigate()
  useEffect(()=>{
     Apiservice.orderList({
         limit: 10,
         offset: 0,
       
     }).then((response)=>{
      if(response.data.status === true){
       
        const orders = response.data.data.orders

        const foundorder =  orders.find(order => order.id === parseInt(orderID.orderId));
        console.log(foundorder)
        if(foundorder){

          setorderdetail(foundorder)
        }else {
          console.error('Order not found');
        }
        
      }else{
           console.error(response.data)
      }
     }).catch((err)=>{
      console.error(err);
     })
  },[]);
  
   const backbutton = ()=>{
    navigate('/orderhistory');
   }

   const formatDate = (dateString) => {
    // Use Date object to format the date
    const date = new Date(dateString);
    // Customize the date formatting as needed
    const formattedDate = date.toDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    return formattedDate;
  };
  return (
    <div>
     <Navbar/>
    <div className="order-details-container">
   
        <>
      <section className="order-details-section">
        <div className="order-details-header">
          <h2>Order Details</h2>
          
        </div>
        <div className='order-history-orderid-section'>
          <div className='order-history-orderid'>
        <p>Order ID:</p>
        <p>#{orderdetail.id}</p>
          </div>
          <div className='order-history-orderid'>
          <p>Total Price:</p>
        <p >₹{orderdetail.order_amount}</p>
          </div>
        </div>
        {orderdetail.product_details && orderdetail.product_details.map((item,index)=>(

          <div className="order-items-list">
            <section className='order-items-list-section'>

          <div className="order-item">
            
            <div className="order-item-image">
             
              <img src={URL+item.image} alt="Product" />
            </div>
            <div className="order-item-details">
              <h3>{item.name}</h3>
             
           
              <small>{item.size}</small>
            </div>
          </div>
          <div className='order-items-price'>
          {item.discount_type === "percent" ? (
                <p>
                 ₹{ item.price - (item.price * (item.discount/100) )}
                </p>
              ):
              <p>
             ₹{item.price - item.discount}
              </p>
                

              }
          
          </div>
                </section>
          {/* Add more order items as needed */}
        </div>
                ))}

                <section className='order-history-orderid-section'>
                  {orderdetail.is_subscribe === 1 && (

                    <div>

                    <div className='order-history-orderid'>
          <p>Subscription Type:</p>
        <p >{orderdetail.subscriptions?.subscription_type}</p>
          </div>
                <div className='order-history-orderid'>
          <p>Start Date:</p>
        <p >{formatDate(orderdetail.subscriptions?.subscription_date)}</p>
          </div>
                <div className='order-history-orderid'>
          <p>End Date:</p>
        <p >{formatDate(orderdetail.subscriptions?.subscription_end)}</p>
          </div>
                    </div>
                      )}
                <div className='order-history-orderid'>
          <p>Delivery Preferences:</p>
        <p >{orderdetail.delivery_instructions}</p>
          </div>
                </section>
        <div className="order-summary">
          <h3>Order Summary</h3>
          <div className='order-summary-1'>
          <p>Payment status:</p>
          <small style={{ color: orderdetail.payment_status === 'paid' ? 'green' : 'red', textTransform: 'uppercase' }}>
          {orderdetail.payment_status}
        </small>
          </div>
          <div className='order-summary-1'>
          <p>Order status: </p>
          <p style={{ color: orderdetail.order_status === 'accepted' ? 'green' : 'red', textTransform:'capitalize'}} >
            {orderdetail.order_status}</p>
          </div>
          <div className='order-summary-1'>
          <p>Delivery charge: </p>
          <p>{orderdetail.delivery_charge}</p>
          </div>
          {orderdetail.is_subscribe === 1 ? 
           <div className='order-summary-1'>
          <p>Due Amount:</p>
          <h5 style={{color:"green"}}>₹{orderdetail.order_amount}</h5>
          </div> :
            <div className='order-summary-1'>
            <p>Total:</p>
            <h5 style={{color:"green"}}>₹{orderdetail.order_amount}</h5>
            </div>
          }
          
        </div>
        <div className="order-actions">
          <button className="btn-back" onClick={backbutton}>Back</button>
          {/* <button className="btn-track">Track Order</button> */}
        </div>
      </section>
      </>
    
    </div>
    <Footers/>
    </div>
  );
}
