export const QuantitySelector = ({ quantity, onDecrease, onIncrease }) => {
    return (
      <div className="quantity-selector">
        <button className="quantity-btn-1" onClick={onDecrease}>
          <span>-</span>
        </button>
        <span className="quantity-display">{quantity}</span>
        <button className="quantity-btn-2" onClick={onIncrease}>
          <span>+</span>
        </button>
      </div>
    );
  };
  
  
 export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }