// // VerifyPhone.js
// import React, { useEffect, useRef, useState } from "react";
// import "./verifyphone.css";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import Apiservice from "../../../../Api/ApiController";
// import Swal from "sweetalert2";

// const VerifyPhone = ({ phone, otp, closePopup, onVerificationSuccess }) => {
//   const [enteredOTP, setEnteredOTP] = useState("");
//   const [Otp,setOtp] =  useState(['','','',''])
//   const [resendotp, setresendotp] = useState();
//   const [showPopup, setShowPopup] = useState(false);
//   const navigate = useNavigate();
//   const inputRefs = useRef([]);
//   const mobile = sessionStorage.getItem("phone", phone);
//   const handleOTPChange = (e) => {
//     setEnteredOTP(e.target.value);
//   };

//   const handleVerify = async (e) => {
//     e.preventDefault();
//     try {
//       // const response = await axios.post(
//       //   "https://desigro.languagenectar.in/api/v1/auth/verify-phone",
//       //   {
//       //     otp: enteredOTP,
//       //     phone: mobile,
//       //   }
//       // );
//        const response =  await Apiservice.verifyOtp({
//         otp: enteredOTP,
//         phone: mobile,
//        })
//       if (response.data.status === true) {
//         const authToken = response.data.token;
//         sessionStorage.setItem("authtoken", authToken);
//         sessionStorage.setItem("userID",response.data.data.id)
//         const profileInfo = response.data.data.f_name;
//         if (profileInfo) {
//           navigate("/");
//         } else {
          
//           navigate("/addprofile");
//         }
        
//         window.location.reload()
//       } else {
//         console.error("Phone Verification Failed:", response.data.message);
//       }
//     } catch (error) {
//       console.log('Error Details:', error.response.data.message);
//      // Check if the error response contains validation errors
//      if (error.response && error.response.data && error.response.data.errors) {
//       const validationErrors = error.response.data.errors;

//       // Extract error messages from each object in the array
//       const errorMessages = validationErrors.map(error => error.message);

//       // Create a formatted error message string
//       const formattedErrorMessages = errorMessages.join('\n');

//       // Show the validation error messages using SweetAlert2
//       Swal.fire({
//           icon: 'error',
        
//           text: formattedErrorMessages, // Display formatted error messages
//           toast: true,
//           position: 'top-end',
//           showConfirmButton: false,
//           timer: 2000
//       });
//   } else {
//       // Show a generic error message if no validation errors are present
//       Swal.fire({
//           icon: 'error',
//           text: error.response.data.message,
//           toast: true,
//           position: 'top-end',
//           showConfirmButton: false,
//           timer: 2000
//       });
//   }
//     }
//   };
//   const handleResendOtp = async () => {
//     try {
//       const response = await axios.post(
//         "https://desigro.languagenectar.in/api/v1/auth/resend-otp",
//         {
//           phone: mobile,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//           },
//         }
//       );

//       if (response.data.status) {
//         setShowPopup(true);
//         setresendotp(response.data.otp);
//         setEnteredOTP("")
//         console.log("OTP Resent Successfully");
//       } else {
//         console.error("OTP Resend Failed:", response.data.message);
//       }
//     } catch (error) {
//       console.error("OTP Resend Error:", error);
//     }
//   };
//   useEffect(() => {
//     if (showPopup && resendotp) {
//       const timeoutId = setTimeout(() => {
//         setShowPopup(false);
//       }, 3000);

//       return () => clearTimeout(timeoutId);
//     }
//   }, [showPopup, resendotp]);
//   // const handleOTPChangeAtIndex = (e, index) => {
//   //   const newOTP = [...enteredOTP]; // Create a copy of the current OTP array
//   //   newOTP[index] = e.target.value; // Update the digit at the specified index with the new value
//   //   setEnteredOTP(newOTP.join("")); // Update the state with the new OTP array joined into a string
//   //   if (index < 3 && e.target.value !== "") {
//   //     inputRefs[index + 1].current.focus();
//   //   }
//   // };
//   // const handleOTPChangeAtIndex = (e, index) => {
//   //   const newOTP = [...enteredOTP]; // Create a copy of the current OTP array
  
//   //   if (e.target.value === "") {
//   //     // Handle backspacing by focusing on the previous input field and clearing its value
//   //     if (index > 0) {
//   //       inputRefs[index - 1].current.focus();
//   //       newOTP[index - 1] = ""; // Clear the value in the previous input field
//   //     }
//   //   } else {
//   //     // Update the digit at the specified index with the new value
//   //     newOTP[index] = e.target.value;
      
//   //     // Focus on the next input field if available
//   //     if (index < inputRefs.length - 1) {
//   //       inputRefs[index + 1].current.focus();
//   //     }
//   //   }
  
//   //   setEnteredOTP(newOTP.join("")); // Update the state with the new OTP array joined into a string
//   // };
  
//   const handleOTPInput = (index, value) => {
//     const newOTP = [...Otp];
//     newOTP[index] = value;

//     // Update OTP array
//     setEnteredOTP(newOTP);

//     // Clear the input field if the entered value is empty
//     if (value === '') {
//       // Move focus to the previous input field
//       if (index > 0) {
//         inputRefs.current[index - 1].focus();
//       }
//     } else {
//       // Move focus to the next input field
//       if (index < Otp.length - 1) {
//         inputRefs.current[index + 1].focus();
//       }
//     }
//   };
//   // const inputRefs = [
//   //   useRef(null),
//   //   useRef(null),
//   //   useRef(null),
//   //   useRef(null),
//   // ];
//   const navigatetochangenumber = ()=>{
//     navigate('/signup')
//   }

//   const Modalclose = ()=>{
//     setShowPopup(false);
//   }

//   const setInputRef = (element, index) => {
//     inputRefs.current[index] = element;
//   };

//   return (
//     <div className="main-div-verify">
//       <div className="sub-div-verify">
        
//        <form className="form-verify" onSubmit={handleVerify}>
//   <div className="title">OTP Verification</div>
//   {/* <div > */}
//    <p className="message" >
//    We have sent a verification code on your mobile number 
//   <span  onClick={navigatetochangenumber}>{mobile}<i class='bx bx-message-square-edit'></i></span></p>
//   {/* </div> */}
//   <div className="inputs">

//      {/* Render four input fields for OTP digits */}
//      {Otp.map((digit, index) => (
//               <input
//                 key={index}
//                 className="input-otp"
//                 type="text"
//                 maxLength="1"
//                 value={digit}
//                 onChange={(e) => handleOTPInput(index, e.target.value)}
//                 ref={(input) => setInputRef(input, index)}
//               />
//             ))}
  
//   </div>
//   <div className='resend-otp-button'>
//   <small  onClick={handleResendOtp}>Resend OTP</small>
//   </div>
//   <div>
//   <button className="action-verify" type="submit">Verify OTP</button>
//   </div>
// </form>

//       </div>
//       {showPopup && (
//         <div className="popup">
//           <p>OTP: {resendotp}</p>
//           <button onClick={Modalclose}>Close</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default VerifyPhone;



import React, { useEffect, useRef, useState } from "react";
import "./verifyphone.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Apiservice from "../../../../Api/ApiController";
import Swal from "sweetalert2";

const VerifyPhone = ({ phone, otp, closePopup, onVerificationSuccess }) => {
  const [enteredOTP, setEnteredOTP] = useState("");
  const [Otp, setOtp] = useState(["", "", "", ""]);
  const [resendotp, setresendotp] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  const mobile = sessionStorage.getItem("phone", phone);

  const handleOTPChange = (e) => {
    setEnteredOTP(e.target.value);
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const verification_code = enteredOTP.join('');
      const response = await Apiservice.verifyOtp({
        otp: verification_code,
        phone: mobile,
      });
      if (response.data.status === true) {
        const authToken = response.data.token;
        sessionStorage.setItem("authtoken", authToken);
        sessionStorage.setItem("userID", response.data.data.id);
        const profileInfo = response.data.data.f_name;
        if (profileInfo) {
          navigate("/");
        } else {
          navigate("/addprofile");
        }

        window.location.reload();
      } else {
        console.error("Phone Verification Failed:", response.data.message);
      }
    } catch (error) {
      console.log("Error Details:", error);
      Swal.fire({
        icon: 'error',
      
        text: "OTP Does not matched", // Display formatted error messages
        toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000
    });
      // Handle error response
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await axios.post(
        "https://desigro.languagenectar.in/api/v1/auth/resend-otp",
        {
          phone: mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.data.status) {
        setShowPopup(true);
        setresendotp(response.data.otp);
        setEnteredOTP("");
        console.log("OTP Resent Successfully");
      } else {
        console.error("OTP Resend Failed:", response.data.message);
      }
    } catch (error) {
      console.error("OTP Resend Error:", error);
    }
  };

  useEffect(() => {
    if (showPopup && resendotp) {
      const timeoutId = setTimeout(() => {
        setShowPopup(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showPopup, resendotp]);

  const handleOTPInput = (index, value) => {
    const newOtp = [...Otp];
    newOtp[index] = value;
    setEnteredOTP(newOtp)
    setOtp(newOtp);

    // Clear the input field if the entered value is empty
    if (value === "") {
      // Move focus to the previous input field
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else {
      // Move focus to the next input field
      if (index < Otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const navigatetochangenumber = () => {
    navigate("/signup");
  };

  const Modalclose = () => {
    setShowPopup(false);
  };

  const setInputRef = (element, index) => {
    inputRefs.current[index] = element;
  };

  return (
    <div className="main-div-verify">
      <div className="sub-div-verify">
        <form className="form-verify" onSubmit={handleVerify}>
          <div className="title">OTP Verification</div>
          <p className="message">
            We have sent a verification code on your mobile number{" "}
            <span onClick={navigatetochangenumber}>
              {mobile}
              <i class="bx bx-message-square-edit"></i>
            </span>
          </p>
          <div className="inputs">
            {/* Render four input fields for OTP digits */}
            {Otp.map((digit, index) => (
              <input
                key={index}
                className="input-otp"
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleOTPInput(index, e.target.value)}
                ref={(input) => setInputRef(input, index)}
              />
            ))}
          </div>
          <div className="resend-otp-button">
            <small onClick={handleResendOtp}>Resend OTP</small>
          </div>
          <div>
            <button className="action-verify" type="submit">
              Verify OTP
            </button>
          </div>
        </form>
      </div>
      {showPopup && (
        <div className="popup">
          <p>OTP: {resendotp}</p>
          <button onClick={Modalclose}>Close</button>
        </div>
      )}
    </div>
  );
};

export default VerifyPhone;
