import React from 'react'
import Navbar from '../../navbar/NavIgationBar'
import Footers from '../../footer/Footers'

export default function ReturnPolicy() {
  return (
    <>
            <div>
                <div>
                    <Navbar />
                    <section>
                        <div className="privacy-head">
                            <h3>Return Policy</h3>
                            <hr className="hrprivacy" />
                        </div>
                        <div className="privacy-policy">
                            <div className="privacy-policy" />
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quidem totam ipsum laboriosam dolorum iure blanditiis nam qui, quasi cumque, eveniet sapiente ex expedita. Tenetur eos distinctio natus hic sapiente dicta delectus enim pariatur qui odit eligendi iure at quas dolore labore itaque, inventore, molestiae corrupti voluptate! Incidunt, pariatur praesentium.
                        </div>
                        <div></div>
                    </section>
                    <Footers />
                </div>
            </div>
        </>
  )
}
