import React from 'react'
import { TbSailboat } from "react-icons/tb";
import { GiRosaShield,GiCaptainHatProfile } from "react-icons/gi";
import './ourprocess.css';
import Navbar from '../../navbar/NavIgationBar';
import Footers from '../../footer/Footers';
import { Outlet } from 'react-router-dom';
import ourprocess from './ourprocess.png'
export default function OurProcess() {
  return (
    <div>
      <Outlet/>
        <div>
            <Navbar/>
      <section>
        <div className='ourprocess-head'>
            <h3>THE DESI MILK™ PROCESS</h3>
            <hr />
            </div>
        <div className='process-cards'>
            <div className='boat'>
            <TbSailboat  style={{width:"70px" , height:'auto'}}  />
           

            <h3>Farmers</h3>
            <h2>(No Middleman) →</h2>
            <p>Indigenous & Healthy Gir Cow, Buffalo & Goat Milked</p>
            </div>
        
      
            <div className='shield'>
            <GiRosaShield  style={{width:"70px" , height:'auto'}}  />
           

            <h3>MILK CHILLING FACILITY IN VILLAGE</h3>
            <h2>(No Middleman) →</h2>
            <p>Milk is stored at Constant 3° C Temperature to maintain Freshness & Natural Properties</p>
            </div>
       
        
            <div className='captain'>
            <GiCaptainHatProfile   style={{width:"70px" , height:'auto'}}  />
           
            <h3>ON TIME DELIVERY</h3>
            <h2>(No Middleman) → Customer</h2>
            <p>Whole, Pure, fresh A2 Milk is delivered in Tamper proof glass bottles within a few hours after milking to our Esteemed Customers</p>
            </div>
      </div>
      </section>
      <section className='ourprocessimage'>
        <img src={ourprocess} alt="" />
      </section>
      <Footers/>
        </div>
    </div>
  )
}
