import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import NavIgationBar from "../../navbar/NavIgationBar";
import Footers from "../../footer/Footers";
import BestSellingProduct from "../../bestsellingproducts/BestSellingProduct";
import "swiper/css";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/thumbs";
import Banner from "./Banner";
import Alertt from "./helpers/Alertt";
import Swiper1 from "./Swiper1";
import { Autoplay } from "swiper";
export default function HomePage() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://desigro.languagenectar.in/api/v1/categories"
      );
      setCategories(response.data.data);
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };


 
  useEffect(() => {
    fetchData();
  }, []);
 
  return (
    <div>
      <div id="HomePage">
        <NavIgationBar />

        <Alertt />

        <Swiper1 />

        <Banner />

        <div className="w-[80%] mx-auto">
          <h1 className="text-xl md:text-4xl text-center my-10 text-[#6ea63e]">
            Shop by Categories
          </h1>

          <Swiper
            effect="coverflow"
            loop={true}
            spaceBetween={10}
            breakpoints={{
              320: { slidesPerView: 2 }, // For small devices
              640: { slidesPerView: 3 }, // For tablets
              768: { slidesPerView: 4 }, // For small desktops
              1024: { slidesPerView: 5 }, // For large screens
            }}
            className="mySwiper"
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
          >
            {categories &&
              categories.length > 0 &&
              categories.map((category, index) => (
                <SwiperSlide key={index}>
                  <div className="r flex flex-col items-center px-2 md:px-5 mt-10">
                    {/* Responsive and rounded image */}
                    <div className="">
                      <NavLink
                        key={category.id}
                        to={`/categories/products/${category.id}`}
                        onClick={() => handleCategoryClick(category.id)}
                      >
                        <img
                          className="rounded-3xl w-20 h-20 sm:w-20 sm:h-20 md:w-32 md:h-32 object-cover mx-auto"
                          src={`https://desigro.languagenectar.in/storage/app/public/category/${category.image}`}
                          alt={category.name}
                        />
                      </NavLink>
                    </div>

                    {/* Centered category text */}
                    <div className=" mt-2">
                      <p className=" text-center text-sm md:text-lg font-medium">
                        {category.name}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>

        <section className="w-[80%] mx-auto">
          <h1 className="text-xl md:text-4xl text-center my-10 text-[#6ea63e]">
            Shop Now
          </h1>
          <BestSellingProduct />
        </section>

        <div className="my-10">
          <img
            className="w-[80%] max-w-[1250px] h-auto object-contain mx-auto"
            src={process.env.PUBLIC_URL + "/assets/homepage/home5.png"}
            alt="Homepage Banner"
          />
        </div>

        <div className="w-[80%] max-w-[1250px] my-10 mx-auto ">
          <div className="row flex flex-col md:flex-row justify-center md:justify-between items-center">
            {/* Text Section */}
            <div className="col-md-6  md:text-left mb-8 md:mb-0 ">
              <h3 className="text-2xl md:text-3xl font-semibold text-gray-800 mb-6">
                We’ve got everything to bring you convenience, trust, and
                assurance
              </h3>
              <p className="text-base md:text-lg text-gray-600 mb-6">
                Desi Gro App is the gateway to all things organic and
                chemical-free, with traceable quality. Here are 3 simple steps
                to use the app and manage your subscriptions with DesiGro A2.
                The app is available on Android.
              </p>
              <div className="">
                <img
                  className="googleplay w-40 md:w-48  hover:scale-105 transform transition duration-300"
                  src={process.env.PUBLIC_URL + "assets/img/googleplaynew.png"}
                  alt="Download on Google Play"
                />
              </div>
            </div>

            {/* Images Section */}
            <div className="col-md-6 flex justify-center md:justify-between gap-4 md:gap-6 px-4">
              <img
                className="img-fluid rounded-lg shadow-lg hover:scale-105 transform transition duration-300"
                src={process.env.PUBLIC_URL + "assets/img/542163805.png"}
                alt="App Screenshot 1"
                style={{ width: "28%" }}
              />
              <img
                className="img-fluid rounded-lg shadow-lg hover:scale-105 transform transition duration-300"
                src={process.env.PUBLIC_URL + "assets/img/542163807.png"}
                alt="App Screenshot 2"
                style={{ width: "28%" }}
              />
              <img
                className="img-fluid rounded-lg shadow-lg hover:scale-105 transform transition duration-300"
                src={process.env.PUBLIC_URL + "assets/img/542163808.png"}
                alt="App Screenshot 3"
                style={{ width: "28%" }}
              />
            </div>
          </div>
        </div>

        <div className="my-10">
          <img
            className="w-[80%] max-w-[1250px] h-auto object-contain mx-auto"
            src={process.env.PUBLIC_URL + "assets/homepage/home4.png"}
            alt=""
          />
        </div>
      </div>
      <Footers />
    </div>
  );
}
