import React, { useEffect, useState } from "react";
import axios from "axios";
import "./WalletPage.css"; // Import your CSS file containing styles
import Swal from "sweetalert2";
import Apiservice from "../../../../Api/ApiController";
import Navbar from "../../../navbar/NavIgationBar";
import Footers from "../../../footer/Footers";
import { useNavigate } from "react-router-dom";

function WalletPage() {
  const [balance, setBalance] = useState(0);
  const [amountToAdd, setAmountToAdd] = useState("");
  const [profile, setProfile] = useState([]);
  const [email, setEmail] = useState("");
  const token = sessionStorage.getItem("authtoken");
  const navigate = useNavigate();

  const handleAmountChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value) && parseInt(value) >= 0) {
      setAmountToAdd(value);
    }
  };

  const handleEmailChange = async (event) => {
    const value = event.target.value;
    setEmail(value);

    if (validateEmail(value)) {
      try {
        const response = await Apiservice.checkEmail({ email: value });
        if (!response.data.status) {
          await Apiservice.registerEmail({ email: value });
          Swal.fire({
            title: "Email registered successfully!",
            icon: "success",
            timer: 1500,
          });
        }
      } catch (error) {
        console.error("Error checking or registering email:", error.message);
      }
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handlePredefinedAmountClick = (amount) => {
    setAmountToAdd(amount.toString());
  };

  const handleAddMoney = async () => {
    try {
      const options = {
        key: "rzp_test_WZu8B3H1Bec0W9",
        amount: parseInt(amountToAdd) * 100,
        currency: "INR",
        name: "My Wallet",
        description: "Add Money to Wallet",
        handler: async function (response) {
          setBalance(balance + parseInt(amountToAdd));
          const transactionId = response.razorpay_payment_id;
          await WalletRecharge(transactionId);
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const WalletRecharge = async (transactionId) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    try {
      const response = await Apiservice.WalletRecharge({
        payment_method: "razor_pay",
        mode: "credit",
        order_amount: amountToAdd,
        transaction_id: transactionId,
        subscription_date: formattedDate,
      });
      if (response.data.status) {
        Swal.fire({
          title: "Wallet recharged successfully!",
          icon: "success",
          timer: 1500,
        });
        getProfileAPI();
      }
    } catch (error) {
      console.error("Error during wallet recharge:", error.message);
    }
  };

  const getProfileAPI = async () => {
    try {
      const response = await Apiservice.getProfile();
      if (response.data.status) {
        setProfile(response.data.data);
        console.log(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching profile:", error.message);
    }
  };

  const transactionhistory = () => {
    navigate("/transactionhistory");
  };

  useEffect(() => {
    getProfileAPI();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="wallet-container">
        <div className="header">
          <h1 className="wallet-heading">My Wallet</h1>
          <div className="balance">Balance: ₹{profile.wallet_balance}</div>
        </div>
        <div className="actions">
          <input
            type="text"
            className="amount-input"
            placeholder="Enter amount"
            value={amountToAdd}
            onChange={handleAmountChange}
            min="0"
          />{" "}
          <br />
          <label className="my-1 font-medium text-gray-500"> Add Email if not Registered </label>{" "}
          <input
            type="email"
            className="email-input"
            placeholder="E-mail ID (Mandatory)"
            value={profile.email}
            onChange={handleEmailChange}
          />
          <div className="predefined-amounts">
            <button
              className="predefined-btn"
              onClick={() => handlePredefinedAmountClick(500)}
            >
              ₹500
            </button>
            <button
              className="predefined-btn"
              onClick={() => handlePredefinedAmountClick(1000)}
            >
              ₹1000
            </button>
            <button
              className="predefined-btn"
              onClick={() => handlePredefinedAmountClick(1500)}
            >
              ₹1500
            </button>
            <button
              className="predefined-btn"
              onClick={() => handlePredefinedAmountClick(2000)}
            >
              ₹2000
            </button>
            <button
              className="predefined-btn"
              onClick={() => handlePredefinedAmountClick(3000)}
            >
              ₹3000
            </button>
            <button
              className="predefined-btn"
              onClick={() => handlePredefinedAmountClick(5000)}
            >
              ₹5000
            </button>
          </div>
          <button
            className="add-money-btn"
            onClick={handleAddMoney}
            disabled={!email || !validateEmail(email)}
            style={{
              backgroundColor: !email || !validateEmail(email) ? "#6b7280" : "", // Gray when disabled
              cursor:
                !email || !validateEmail(email) ? "not-allowed" : "pointer", // No pointer for disabled
            }}
          >
            Add Money
          </button>
        </div>
        <section>
          <div className="transaction-history-btn" onClick={transactionhistory}>
            <h6>Transaction History</h6>
            <i className="bx bx-history"></i>
          </div>
        </section>
      </div>
      <Footers />
    </div>
  );
}

export default WalletPage;
