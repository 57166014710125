import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './signup.css';
import axios from 'axios';
import Apiservice from '../../../../Api/ApiController';
import InputMask from "react-input-mask";
import Swal from 'sweetalert2';
import Navbar from '../../../navbar/NavIgationBar';
import Footers from '../../../footer/Footers';
export default function SignUp() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [otp, setOTP] = useState('');

  const handleSubmit = async (e) => {
    const numericMobileNumber = phone.replace(/\D/g, ''); // Remove non-numeric characters
    console.log(numericMobileNumber.length);
    
    e.preventDefault();
    if (numericMobileNumber.length < 12) {
      Swal.fire({
        icon: 'error',
        text: "Enter a 10-digit Mobile Number",
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000
      });
      return; // Stop further execution
    }

    try {
      const response = await Apiservice.signUp({
        phone:phone
      })

      if (response.data.status ===  true) {
       
        
        sessionStorage.setItem('phone',phone);
        setOTP(response.data.otp);

        // Show the popup
        setShowPopup(true);
      } else {
       console.log(response)
        Swal.fire({
          icon:'error',
          validationMessage: response.errors.message,
          position:'bottom-end',

        })
      }
    } catch (error) {
      console.log('Error Details:', error.response.data);

    // Check if the error response contains validation errors
    if (error.response && error.response.data && error.response.data.errors) {
        const validationErrors = error.response.data.errors;

        // Extract error messages from each object in the array
        const errorMessages = validationErrors.map(error => error.message);

        // Create a formatted error message string
        const formattedErrorMessages = errorMessages.join('\n');

        // Show the validation error messages using SweetAlert2
        Swal.fire({
            icon: 'error',
            text: formattedErrorMessages, // Display formatted error messages
            toast: true,
             position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000
        });
    } else {
        // Show a generic error message if no validation errors are present
        Swal.fire({
            icon: 'error',
            text: 'An error occurred. Please try again later.',
            position: 'bottom-end',
        });
    }
    }
  };

  useEffect(() => {
    if (showPopup && otp) {
      
      const timeoutId = setTimeout(() => {
        setShowPopup(false);
        navigate(`/verify-otp`);
      }, 2000);
  
        return () => clearTimeout(timeoutId);
    }
  }, [showPopup, otp, phone, navigate]);
  
  const closePopupAndNavigate = () => {
    setShowPopup(false);
    navigate('/verify-otp')
  };

  return (
    <>
    <Navbar/>
    <div>
      <div className='main-div'>
        <h1>Login or Signup</h1>
        <div className='sub-main-div'>
          <form onSubmit={handleSubmit} className='form-signup'>
            <div className='form-row'>
            </div>
            <div className='form-row'>
              <div className='form-group'>
                <label htmlFor='phone'>Phone Number</label>
            
                 <InputMask
      mask="+\9\19999999999"
      placeholder="Enter phone number"
      value={phone}
      onChange={(e) => setPhone(e.target.value)}
      className="form-input3"
      />
              </div>
            </div>
            <button className='signup-button' type='submit'>
              Submit
            </button>
          </form>
        </div>
        {showPopup && (
          <div className="popup">
            <p>OTP: {otp}</p>
            <button onClick={closePopupAndNavigate}>Close</button>
          </div>
        )}
      </div>
    </div>
    <Footers/>
        </>
  );
}
