import React, { useEffect, useState } from 'react'
import desigro from '../../footer/desigrologowhite.png';
import './regionselect.css'
import { NavLink, useNavigate } from 'react-router-dom';
import Apiservice from '../../../Api/ApiController';
import Swal from 'sweetalert2';

export default function Regionselect() {
    const [regions, setRegions] = useState([]);
    const [regionName,setregionName] = useState('')
    const [selectedRegionId, setSelectedRegionId] = useState(null);
    const [cities, setCities] = useState([]);
    const [selectedCityId, setSelectedCityId] = useState(null);
    const [location, setLocation] = useState('');
    const navigate =useNavigate()
    const fetchRegions = async () => {
        try {
        
             const response = await Apiservice.ZoneList()
          if (
            response.data &&
            response.data.status &&
            Array.isArray(response.data.data)
          ) {
            setRegions(response.data.data);
          } else {
            console.error("Invalid API response format");
          }
        } catch (error) {
          console.error("Error fetching regions:", error);
        }
      };
    const fetchCities = async (regionId) => {
        console.log(regionId)
        try {
         
          const response =  await Apiservice.citylist(
           regionId
          )
          if (response.data.status === true) {
            console.log(response.data.data)
            setCities(response.data.data);
          } else {
            console.error("Invalid API response format");
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      };
    
      const handleRegionChange = (event) => {
        const selectedId = parseInt(event.target.value);
        const selectedregioNname = event.target.options[event.target.selectedIndex].text;
        setregionName(selectedregioNname)
        setSelectedRegionId(selectedId);
   
        fetchCities(selectedId);
      };
      const handleCityChange = (event) => {
        const selectedCityId = event.target.value;
        const selectedCityName = event.target.options[event.target.selectedIndex].text;
        
        setSelectedCityId(selectedCityId)
        setLocation(selectedCityName); // Set the location to the selected city name
      };
      useEffect(()=>{
        fetchRegions()
      },[])

      const handlezoneadd =(e)=>{
        e.preventDefault()
        Apiservice.AddressAdd({
          location:location,
          region:regionName,
          city_id:selectedCityId,
          region_id:selectedRegionId,
        }).then((response)=>{
          if(response.data.status === true){
               Swal.fire({
                     icon:'success',
                     text:response.data.message
               })
               navigate('/')
          }else{
            Swal.fire({
              icon:'error',
              text:response.data.message
        })
          }
        }).catch((response)=>{
          console.log(response)
          Swal.fire({
            icon: 'error',
          
            text: "Please fill all the details", // Display formatted error messages
            toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar:true
        });
        })
      }
  return (
    <>
     <section className='section-of-select-region'>
        <img src={desigro} alt="" />
        <section className='select-region-section'>
        <div>
            <h5>Select Region and Location</h5>
        </div>
            <form onSubmit={handlezoneadd}>
                <div className='form-group'>
                <select
                        id="regionDropdown"
                        onChange={handleRegionChange}
                        value={selectedRegionId || ""}
                        className='form-select'
                      >
                        <option value="" disabled>
                          Select a region
                        </option>

                        {regions.map((region) => (
                          <option key={region.id} value={region.id}>
                            {region.name}
                          </option>
                        ))}
                      </select>
                </div>
                <div className='form-group'>
                <select id="cityDropdown"  
                   onChange={handleCityChange}
                value={selectedCityId || ''}
                 className='form-select'>
                <option value="" selected disabled>
                          Select a location
                        </option>
                        {cities.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                </div>

                <button>Explore</button>
            </form>
         <div className='notfindyourlocation'>
            <NavLink to="/locationrequest">
                Could not find your location?
                </NavLink> 
         </div>
        </section>

     </section>
    </>
  )
}
