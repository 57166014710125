import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneAlt,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa"; // Icons for contact and social media
import logo from "./desigrologowhite.png";
import Apiservice from "../../Api/ApiController";

export default function Footers() {
  const [product, setproduct] = useState([]);
  const [config, setconfig] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    Apiservice.Shopall({ limit: 10, offset: 0 })
      .then((response) => {
        if (response.data.status === true) {
          setproduct(response.data.data.products);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const HandleProductClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };

  useEffect(() => {
    Apiservice.Config()
      .then((response) => {
        if (response.data.status === true) {
          setconfig(response.data.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <footer
      style={{ backgroundColor: "#03A99C", color: "white" }}
      className="pt-8 pb-6"
    >
      <div className="container px-4">
        {/* Top Links */}
        <div className="flex justify-center space-x-6 mb-6">
          <Link
            to="/return-policy"
            className="text-white no-underline hover:text-gray-300 transition-colors"
          >
            Return Policy
          </Link>
          <Link
            to="/terms-condition"
            className="text-white no-underline hover:text-gray-300 transition-colors"
          >
            Terms and Conditions
          </Link>
          <Link
            to="/privacy-policy"
            className="text-white no-underline hover:text-gray-300 transition-colors"
          >
            Privacy Policy
          </Link>
        </div>

        <div className="row flex flex-wrap gap-y-6 md:gap-y-0">
          {/* Footer Logo */}
          <div className="col-12 col-md-3 text-center md:text-left mb-6 md:mb-0">
            <img
              src={logo}
              alt="DesiGro Logo"
              className="img-fluid mx-auto md:mx-0 mb-4 md:mb-0"
              style={{ maxWidth: "180px" }}
            />
          </div>

          {/* Contact Info */}
          <div className="col-12 col-md-3 text-center md:text-left mb-6 md:mb-0">
            <p className="flex items-center mb-2">
              <FaMapMarkerAlt className="mr-2" />
              {config.address}
            </p>
            <p className="flex items-center mb-2">
              <FaEnvelope className="mr-2" />
              {config.email}
            </p>
            <p className="flex items-center">
              <FaPhoneAlt className="mr-2" />
              {config.phone}
            </p>
          </div>

          {/* Shop Links */}
          <div className="col-12 col-md-3 text-center md:text-left mb-6 md:mb-0">
            <h6 className="text-lg font-bold mb-4">Shop</h6>
            {product.slice(0, 4).map((item) => (
              <p
                key={item.id}
                className="mb-2 cursor-pointer hover:text-gray-300 transition-colors"
                onClick={() => HandleProductClick(item.id)}
              >
                {item.name}
              </p>
            ))}
            <Link
              to="/shop-all"
              className="text-white no-underline hover:text-gray-300 transition-colors"
            >
              View All Products
            </Link>
          </div>

          {/* About Links */}
          <div className="col-12 col-md-3 text-center md:text-left">
            <h6 className="text-lg font-bold mb-4">About DesiGro</h6>
            <Link
              to="/about"
              className="text-white no-underline d-block mb-2 hover:text-gray-300 transition-colors"
            >
              About Us
            </Link>
            <Link
              to="/whychooseus"
              className="text-white no-underline d-block mb-2 hover:text-gray-300 transition-colors"
            >
              Why Choose Us
            </Link>
            <Link
              to="/ourprocess"
              className="text-white no-underline d-block mb-2 hover:text-gray-300 transition-colors"
            >
              Our Process
            </Link>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="flex justify-center space-x-6 mt-6">
          <Link
            to="#"
            className="text-white hover:text-gray-300 transition-colors"
          >
            <FaFacebook size={24} />
          </Link>
          <Link
            to="#"
            className="text-white hover:text-gray-300 transition-colors"
          >
            <FaInstagram size={24} />
          </Link>
          <Link
            to="#"
            className="text-white hover:text-gray-300 transition-colors"
          >
            <FaTwitter size={24} />
          </Link>
          <Link
            to="#"
            className="text-white hover:text-gray-300 transition-colors"
          >
            <FaLinkedin size={24} />
          </Link>
        </div>

        {/* Bottom Section */}
        <div className="text-center mt-8">
          <p>
            © 2024, The Organic Carbon Pvt. Ltd.{" "}
            <Link
              to="/"
              className="text-white no-underline font-bold hover:text-gray-300 transition-colors"
            >
              Desigro
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
}
