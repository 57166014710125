import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './ProductCalender.css'; // Import custom CSS for styling
import axios from 'axios';
import Navbar from '../navbar/NavIgationBar';
import Footers from '../footer/Footers';
import { Api_Base_Url } from '../../Api/ApiController';
const ProductCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [productList, setProductList] = useState([]);
  const token = sessionStorage.getItem("authtoken");
  const [updateqty, setUpdateQty] = useState({});

  useEffect(() => {
    fetchProductList(selectedDate);
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    fetchProductList(date);
  };

  const year = selectedDate.getFullYear();
const month = selectedDate.getMonth() + 1; // Note: Months are zero-based, so January is 0, February is 1, etc.
const day = selectedDate.getDate();

const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  const fetchProductList = async (date) => {
    const indianDate = new Date(date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
    try {
      const response = await axios.get(`${Api_Base_Url}customer/order/get-filter-subscription`, {
        params: {
          date: indianDate.toLocaleDateString(),
          mode: "debit"
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === true) {
        setProductList(response.data.data);
        console.log(response.data.data)
        const productListData = response.data.data
        const customSubscription = productListData.find(product => product.subscription_type === 'custom');

      if (customSubscription) {
        const daysOfWeek = JSON.parse(customSubscription.days);
        const quantities = JSON.parse(customSubscription.qty);

        const dayIndex = indianDate.getDay(); // 0 for Sunday, 1 for Monday, and so on
        const qtyForToday = quantities[dayIndex];

        console.log(daysOfWeek,qtyForToday)
        customSubscription.qty_on_demand = qtyForToday;
      }
      } else {
        setProductList([]);
      }
    } catch (error) {
      console.error('Error fetching product list:', error);
      setProductList([]);
    }
  };

  const updateProductQty = async (productId, newQty) => {
    try {
      const response = await axios.put(`${Api_Base_Url}customer/order/update-subscription`, {
        qty_on_demand: newQty,
        id: productId,
        date_on_demand: formattedDate

      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === true) {
        const updatedQty = { ...updateqty };
        updatedQty[productId] = newQty;
        setUpdateQty(updatedQty);
        fetchProductList(selectedDate);
        console.log(response.data)
      } else {
        console.log("Failed to update product quantity:", response.data);
      }
    } catch (error) {
      console.error('Error updating product quantity:', error);
    }
  };

  return (
    <div>
        <Navbar/>
    <div className="product-calendar-container">
      <h2 className="title">Select a date:</h2>
      <Calendar
        onChange={handleDateChange}
        value={selectedDate}
        className="calendar"
      />
      <div className="product-list-container">
        <h2 className="title">Orders for {selectedDate.toDateString()}:</h2>
        <ul className="product-list">
          {productList.length > 0 ? (
            productList.map((product, index) => (
              <li key={index} className="product-item">
                <img src={`https://desigro.languagenectar.in/storage/app/public/product/${product.image}`} alt={product.name} className="" width={'100px'} />
                <p>{product.name}</p>
                <div className='selector-qty'>
                  <button className='selector-qty-btn' onClick={() => updateProductQty(product.id, (updateqty[product.id] || 0) - 1)}>-</button>
                  <p>{updateqty[product.id] !== undefined ? updateqty[product.id] : (product.qty_on_demand != null && product.qty_on_demand != "0" ? product.qty_on_demand : product.today_qty)}</p>
                  <button className='selector-qty-btn' onClick={() => updateProductQty(product.id, (updateqty[product.id] || 0) + 1)}>+</button>
                </div>
              </li>
            ))
          ) : (
            <li>No products available for this date</li>
          )}
        </ul>
      </div>
    </div>
    <Footers/>
    </div>
  );
};

export default ProductCalendar;
