import React, { useEffect, useState } from 'react'
import refer_earn from './refer_background.jpg';
import './referearn.css';
import high_five from './high_five.png'
import price_desc from './price_desc.svg'
import user_refer from './user-refer.png'
import Navbar from '../../navbar/NavIgationBar';
import Footers from '../../footer/Footers';
import Apiservice from '../../../Api/ApiController';
export default function ReferEarn() {
const [config,setconfig] =  useState([]);
const [profile,setprofile] =  useState([])
    const Config =()=>{
     Apiservice.Config().then((response)=>{
        if(response.data.status === true){
           console.log(response.data.data)
           setconfig(response.data.data)
        }else{

        }
     }).catch((err)=> console.error(err))
    }

    const Profile =()=>{
        Apiservice.getProfile().then((response)=>{
            if(response.data.status === true){
                setprofile(response.data.data)
            }else{
                console.log(response)
            }
        }).catch((err)=> console.error(err))
    }
useEffect(()=>{
    Config()
    Profile()
},[])
    const copyrefrralcode = ()=>{
        const refercode = document.querySelector('.refferal-code-user')
        if(refercode){
           const code = refercode.textContent
            navigator.clipboard.writeText(code).then(()=>{
                alert('Copy to clipboard' + code)
            })
        }
    }
    const shareOrInvite = async () => {
        const refercode = document.querySelector('.refferal-code-user');
        const code = refercode.textContent;
    
        if (!navigator.share) {
          alert('Browser does not support sharing');
        } else {
          try {
            await navigator.share({
              title: 'DesiGro',
              text: code,
            });
          } catch (error) {
            console.error('Error sharing:', error);
          }
        }
      };
  return (
    <>
    <Navbar/>
     <section>
           <div className='refer-earn-image'>
            <img src={refer_earn} alt="" />
           </div>
            

            <section className='refer-description'>
                <div className='user_refer-section'>
                    <div className='user_refer-image'>
                        <img src={user_refer} alt="" />
                    </div>
                    <div>
                        <p>Invite your friend to download app with your referral code</p>
                    </div>
                </div>
                {/* ============================================= */}
                <div className='user_refer-section'>
                    <div className='user_refer-image'>
                        <img src={high_five} alt="" />
                    </div>
                    <div>
                        <p>Tell to register with your referral code</p>
                    </div>
                </div>
                {/* ============================================= */}
                <div className='price_desc-section'>
                    <div className='price_desc-image'>
                        <img src={price_desc} alt="" />
                    </div>
                    <div>
                        <p>If they do a recharge of Rs.{config.minimum_coin_transfer},<br/>you will get Rs.{config.service_coin_on_referral},& your friend will get Rs.{config.service_coin_on_registration}</p>
                    </div>
                </div>
                {/* ============================================= */}
            </section>

            <section className='referral-code-section'>
                <div className='referral-code-head'>YOUR REFERRAL CODE</div>
                <div className='refferal-code-user'>
                    {profile.referral_code}
                </div>
                <div className='referral-code-copy' onClick={copyrefrralcode}>
                    Tap to copy
                </div>
            </section>

            <div className='referral-code-invite'>
             <button onClick={shareOrInvite}><i class='bx bxs-share-alt' ></i>{"     "}INVITE</button>
            </div>
     </section>
     <Footers/>
    </>
  )
}
