import React, { useEffect, useState } from 'react'
import Apiservice from '../../../../Api/ApiController';
import Navbar from '../../../navbar/NavIgationBar';
import Footers from '../../../footer/Footers';
import './transactionhistory.css'
export default function TransactionHistroy() {
  const [startDate,setstartDate] = useState('')
  const [endDate,setendDate] = useState('')
  const [transactionhistory,settransactionhistory] =  useState([]);

  const Wallethistory = ()=>{
    Apiservice.WalletHistory({
      start_date:startDate,
      end_date:endDate
    }).then((response)=>{
      if(response.data.status === true){
           console.log(response)
           settransactionhistory(response.data.data)
      }else{
       console.error(response);
      }
    }).catch((err)=> console.log(err))
  };
  useEffect(()=>{
    Wallethistory()
  },[startDate,endDate])
  
  return (
    <div>
      <Navbar/>
       <div className="history">
    <div className="form-group">
      <label htmlFor="startdate">Start date</label>
      <input type="date" id='startdate' value={startDate} onChange={(e)=>setstartDate(e.target.value)}  />
    </div>
    <div className="form-group">
      <label htmlFor="enddate">Start date</label>
      <input type="date" id='enddate' value={endDate} onChange={(e)=>setendDate(e.target.value)}  />
    </div>
    <div className='transactionhistory-main'>
    <h2 className="history-heading">Transaction History</h2>
{transactionhistory.map((transaction,index)=>(
  <ul key={transaction.id} className="transaction-list">
     <li>
        <div>transaction Id : {transaction.transaction_id}</div>
      <div className='date-section-of-transactionhistory'>
      <p>{new Date(transaction.created_at).toLocaleDateString()}</p>
        <div className='ruppee-section-of-transactionhistory'>
          <p>Rupees</p>
        <p>{transaction.order_amount}</p>
        </div>
      </div>
     </li>
    </ul>
))}
</div>
  </div>
  <Footers/>
  </div>
  )
}
