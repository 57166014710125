import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Swiper1 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed:1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    lazyLoad: "ondemand",
    pauseOnHover: true,
    centerMode: true,
    centerPadding: "0px",
  };

  return (
    <div className="overflow-hidden md:w-[80%] mx-auto mb-4">
      <Slider {...settings}>
        <div className="rounded-lg px-4 focus:outline-none">
          <img
            src={process.env.PUBLIC_URL + "/assets/homepage/home1.png"}
            alt="Slide 1"
            className="w-full h-full object-cover rounded-xl"
          />
        </div>
        <div className="rounded-lg px-4 focus:outline-none">
          <img
            src={process.env.PUBLIC_URL + "/assets/homepage/home2.png"}
            alt="Slide 2"
            className="w-full h-full object-cover rounded-xl"
          />
        </div>
        <div className="rounded-lg px-4 focus:outline-none">
          <img
            src={process.env.PUBLIC_URL + "/assets/homepage/home4.png"}
            alt="Slide 4"
            className="w-full h-full object-cover rounded-xl"
          />
        </div>
        <div className="rounded-lg px-4 focus:outline-none">
          <img
            src={process.env.PUBLIC_URL + "/assets/homepage/home5.png"}
            alt="Slide 5"
            className="w-full h-full object-cover rounded-xl"
          />
        </div>
      </Slider>
    </div>
  );
};

export default Swiper1;
