import React, { useEffect, useState } from "react";
import "./navbar.css";
import logo from "./DesiGrologo.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import telephone from  './frame7.png'
import Swal from "sweetalert2";
import Apiservice from "../../Api/ApiController";
const Navbar = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("authtoken");
  const [CartItems, setCartItems] = useState([]);

  const [showMenu, setShowMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const [visible, setVisible] = useState(true);
  const [fristname,setfirstname] = useState();
  const [lastname,setlastname] = useState();
  const [email,setemail] =  useState('');
  const [contactnumber,setcontactnumber] =  useState();
  const [description,setdescription] = useState()
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const URL = 'https://desigro.languagenectar.in/storage/app/public/product/';
  const handleSearch = () => {
    // e.preventDefault();
    if (searchQuery.trim() !== "") {
      navigate(`/search?name=${encodeURIComponent(searchQuery)}`);
    }
  };
  
  
  
  const Searchproduct = ()=>{
    Apiservice.Search({
      name:searchQuery
    }).then((response)=>{
      if(response.data.status === true){
        setSearchResults(response.data.data.products)
        console.log(response.data)
      }else{
        
      }
    }).catch((err)=>console.error(err))
  }
  
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    // handleSearch()
    Searchproduct()
  };
     
  useEffect(()=>{
   Apiservice.cartdetails().then((response)=>{
        setCartItems(response.data.data.length)
   })
  },[])
  
  const Offmenu = () => {
    setShowMenu(false);
    
  };
  const CategoriesNavbar = async () => {
    try {
      
      const response = await Apiservice.Category()
      setCategories(response.data.data);
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  useEffect(() => {
    CategoriesNavbar();
    
  }, []);

  useEffect(()=>{
    Offmenu()
  },[location,Offmenu])
  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const HandleAccessProfile = () => {
      navigate("/profile");  
  };

  
  const ContactUssubmit = async (e) => {
    e.preventDefault();
  
    // Validation checks
    if (!fristname || !lastname || !contactnumber || !email || !description) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please fill out all required fields.",
        showConfirmButton: false,
        timer: 1500
      });
   
      return;
    }
  
    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please enter a valid email address.",
        showConfirmButton: false,
        timer: 1500
      });
  
      return;
    }
  

  
    try {
    
      const response = await Apiservice.ContactUs({
          first_name: fristname,
          last_name: lastname,
          phone: contactnumber,
          email: email,
          description: description,
      })
      if (response.data.status === true) {
        // console.log(response.data.message);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your Query has been saved",
          showConfirmButton: false,
          timer: 1500
        });
        setfirstname("");
        setlastname("");
        setemail("");
        setdescription("");
        setcontactnumber("");
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const [showDropdown, setShowDropdown] = useState(false);

const handleDropdownClick = () => {
  setShowDropdown(false); // Hide the dropdown on click
};
const HandleProductClick = (productId) => {
  navigate(`/product-details/${productId}`);
};
  return (
    <div className={`${visible ? "visible" : "hidden"}`}>
      {!showMenu ? (
        <div>
          <div
            className="hamburger-menu"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            &#9776;
          </div>
        </div>
      ) : (
        <button
          type="button"
          className="btn-close hamburger-menu1"
          onClick={Offmenu}
          style={{ color: "white" }}
        ></button>
      )}


      {/* main navbar */}
      <div className="flex  items-center justify-between">

          <div className="logo">
            <img src={logo} alt="logo" />
          </div>

          <div className={`nav-links ${showMenu ? "show" : ""}`}>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/shop-all">Shop All</NavLink>

            <div className="dropdown-main">
              <NavLink
                href="#"
                onMouseOver={() => {
                  setShowDropdown(true); // Show dropdown on mouse over
                }}
              >
                Categories
              </NavLink>
              {showDropdown && (
                <div className="dropdown-content-main">
                  {categories.map((category,index) => (
                    <div className="dropdown-con-main" key={index}>
                      <NavLink
                        to={`/categories/products/${category.id}`}
                        onClick={() => handleCategoryClick(category.id)}
                      >
                        {category.name}
                      </NavLink>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <NavLink to="/about">About Us</NavLink>

            {token ? <NavLink to="/calender">Calendar</NavLink> : ""}
            <div className="dropdown-main">
              <NavLink
                href="#"
                onMouseOver={() => {
                  setShowDropdown(true); // Show dropdown on mouse over
                }}
              >
                Our Services<i className="bx bxs-chevron-down"></i>
              </NavLink>
              {showDropdown && (
                <div className="dropdown-content-main">
                  <div className="dropdown-con-main">
                    <NavLink to="/referearn">Refer & Earn</NavLink>
                    <NavLink to="/whychooseus">why us</NavLink>
                    <NavLink to="/ourprocess">Our Process</NavLink>
                    <NavLink
                      data-bs-target="#exampleModalToggle"
                      data-bs-toggle="modal"
                    >
                      Contact Us
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={`navbar-icons ${showMenu ? "show" : ""} `}>
            <div className="container h-100 d-flex align-items-center justify-content-between gap-2 ">
              <div
                onClick={() => setShowSearchInput(!showSearchInput)}
                className="icon"
              >
                <i className="bx bx-search"></i>
              </div>
              <div className="icon" onClick={HandleAccessProfile}>
                <i className="bx bx-user"></i>
              </div>
              <div className="notification-badge-container">
                <NavLink className="icon" to="/notification">
                  <i className="bx bx-bell"></i>
                  {CartItems > 0 && (
                    <span className="notification-badge-1">{CartItems}</span>
                  )}
                </NavLink>
              </div>
              <div className="icon">
                <div className="position-relative d-inline-block cart-icon">
                  <div className="notification-badge-container">
                    <NavLink to="/cart" className="icon-font">
                      <i className="bx bx-cart-add"></i>
                      {CartItems > 0 && (
                        <span className="notification-badge">{CartItems}</span>
                      )}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>
{/* main navbar closed */}
      <div
        className="offcanvas offcanvas-end nav-side-mobile"
        data-bs-scroll="true"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header">
          <div className="mobile-view-icons">
            <div className="icon" onClick={HandleAccessProfile}>
              <i className="bx bx-user"></i>
            </div>
            <div className="icon">
              <div className="position-relative d-inline-block">
                <NavLink to="/cart" className="icon-font">
                  <i className="bx bx-cart-add"></i>
                </NavLink>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="searchbar">
            <input
              className="search_input"
              type="text"
              name=""
              placeholder="Search..."
              value={searchQuery}
              onChange={handleInputChange}
            />
            <a onClick={handleSearch} className="search_icon">
              <i className="bx bx-search"></i>
            </a>
          </div>
          <div className="nav-links-mobile">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/shop-all">Shop All</NavLink>
            <div className="dropdown-main">
              <div className="dropbtn">Categories</div>
              <div className="dropdown-content-main">
                {categories.map((category,index) => (
                  <div className="dropdown-con-main">
                    <NavLink
                      key={index}
                      to={`/categories/products/${category.id}`}
                      onClick={() => handleCategoryClick(category.id)}
                      style={{ color: "black" }}
                    >
                      {category.name}
                    </NavLink>
                  </div>
                ))}
              </div>
            </div>
            <NavLink to="/about">About Us</NavLink>
            <NavLink to="/whychooseus">why us</NavLink>
            <div data-bs-target="#exampleModalToggle" data-bs-toggle="modal">
              Contact
            </div>
            <NavLink to="/ourprocess">Our Process</NavLink>
            {token ? <NavLink to="/calender">Calendar</NavLink> : ""}
          </div>
        </div>
      </div>

      <section>
        <div
          className="modal fade"
          id="exampleModalToggle"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="contact-header-modal">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <section className="contact-modal-form">
                  <div className="caontact-modal-img">
                    <img src={telephone} alt="" />
                  </div>
                  <div className="form-modal-for-contact">
                    <form
                      onSubmit={ContactUssubmit}
                      className="form-modal-for-contact-1"
                    >
                      <div>
                        <h2>
                          Get In <b>Touch</b>
                        </h2>
                        <h6>Any question? we would be happy to help you!</h6>
                      </div>
                      <div className="row">
                        <div className="form-group">
                          <div class="input-icon-contact">
                            <input
                              type="text"
                              value={fristname}
                              onChange={(e) => setfirstname(e.target.value)}
                              placeholder="First name"
                              required
                            />
                            <i className="bx bx-user-circle"></i>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-icon-contact">
                            <input
                              type="text"
                              value={lastname}
                              onChange={(e) => setlastname(e.target.value)}
                              placeholder="last name"
                              required
                            />
                            <i className="bx bx-user-circle"></i>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-icon-contact">
                          <input
                            type="tel"
                            value={contactnumber}
                            pattern="[0-9]*"
                            onChange={(e) => setcontactnumber(e.target.value)}
                            placeholder="Contact number"
                            required
                          />
                          <i className="bx bx-phone-call"></i>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-icon-contact">
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                            placeholder="Email address"
                            required
                          />
                          <i className="bx bx-envelope"></i>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-icon-contact-1">
                          <textarea
                            type="text"
                            value={description}
                            onChange={(e) => setdescription(e.target.value)}
                            placeholder="Description"
                            required
                          />
                          <i className="bx bx-edit-alt"></i>
                        </div>
                      </div>
                      <div className="contact-modal-btn-1">
                        <button type="submit" className="contact-modal-btn">
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        {showSearchInput && (
          <div className="modal-search">
            <div className="modal-content-search">
              <span
                className="close-search"
                onClick={() => setShowSearchInput(false)}
              >
                &times;
              </span>

              <div className="search_input_modal">
                <input
                  type="text"
                  name=""
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleInputChange}
                />

                <div onClick={handleSearch} className="search_icon_modal">
                  <i className="bx bx-search"></i>
                </div>
              </div>
              <div className="modal-list-search">
                <ul>
                  {searchResults.map((result, index) => (
                    <li
                      onClick={() => HandleProductClick(result.id)}
                      key={index}
                    >
                      <div className="search-resuly-list">
                        <span>
                          <img src={URL + result.image} alt="" />
                        </span>
                        {result.name}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </section>

    </div>
  );
};

export default Navbar;
