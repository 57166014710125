import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '250px',
};

const GoogleMaps = ({center}) => {
    
    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: 'AIzaSyB-dfXTj2hUMYzTJjm7cURsllyFm1W3tBM',
      libraries,
    });

    if (loadError) {
      return <div>Error loading maps</div>;
    }
  
    if (!isLoaded) {
      return <div>Loading maps</div>;
    }
  
    return (
      <div>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={10}
          center={center}
        >
          <Marker position={center}  />
        </GoogleMap>
      </div>
    );
  };
  
  export default GoogleMaps;