


import React, { useEffect, useState } from "react";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import "./productdetails.css";
import NavIgationBar from "../../navbar/NavIgationBar";
import Footers from "../../footer/Footers";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import Apiservice from "../../../Api/ApiController";
import Swal from "sweetalert2";

export default function ProductDetails() {
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [productquantity, setProductQuantity] = useState(1);
  const token = sessionStorage.getItem('authtoken');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productResponse = await axios.get(`https://desigro.languagenectar.in/api/v1/services/details/${productId}`);
        setProductDetails(productResponse.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product details:', error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [productId]);

  const handleAddToCart = async (e) => {
    e.preventDefault();
    if(!token){
      Swal.fire({
        icon: 'warning',
        text: 'Please login First',
        html: `You need to Login`,
        showCancelButton: false, 
        confirmButtonText: 'Login', 
        cancelButtonText: 'Cancel', 
      }).then((result) => {
        if (result.isConfirmed) {
        
          window.location.href = '/signup';
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        
        }
      });
  
        // Close the SweetAlert dialog after 5 seconds (5000 milliseconds)
        setTimeout(() => {
          Swal.close();
          
        }, 5000);
    }else{

      try {
        await Apiservice.Addtocart({
        id: productId,
        quantity: productquantity
      }).then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: 'success',
            text: response.data.message
          });
        } else {
          console.error(response.data);
        }
      }).catch((err) => console.log(err));
    } catch (error) {
      console.error('Error adding product to cart:', error.message);
    }
  }
  };

  const increaseQuantity = () => {
    setProductQuantity(productquantity + 1);
  };

  const decreaseQuantity = () => {
    if (productquantity > 0) {
      setProductQuantity(productquantity - 1);
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader">
          <PuffLoader loading={loading} size={60} color="#217b10" />
        </div>
      </div>
    );
  }

  return (
    <div>
    <NavIgationBar />
    <div className="detailsPage">
  
    <div className="breadcrumbWrapper">
      <div className="breadcrumbContainer">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/shop-all">Shop All</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Product Details
          </li>
        </ol>
      </div>
    </div>
  
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="productZoom">
            <img
              src={productDetails && productDetails.image ? `https://desigro.languagenectar.in/storage/app/public/product/${productDetails.image}` : ''}
              alt="Product"
              className="product-details-image"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="productInfo">
            <h1>{productDetails.name}</h1>
            <div className="ratings">
              {Array.from({ length: Math.floor(productDetails.avg_rating) }, (_, index) => (
                <span key={index}>⭐</span>
              ))}
            </div>
            <div className="priceSec">
              {productDetails.discount_type === 'percent' ? (
                <>
                  <span>₹{productDetails.price - (productDetails.price * (productDetails.discount / 100))}</span>
                  <span className="discount">({productDetails.discount}% off)</span>
                </>
              ) : (
                <>
                  <span>₹{productDetails.price - productDetails.discount}</span>
                  <span className="discount">(₹{productDetails.discount} off)</span>
                </>
              )}
              <s className="olderPrice">₹{productDetails.price}</s>
            </div>
            <h5>Product Description</h5>
            <p>{productDetails.description}</p>
            <div className="quantityContainer">
              <button className="quantityBtn" onClick={decreaseQuantity}>-</button>
              <input className="quantityInput" type="text" value={productquantity} readOnly />
              <button className="quantityBtn" onClick={increaseQuantity}>+</button>
            </div>
            <button className="addToCartBtn" onClick={handleAddToCart}>Add to Cart</button>
          </div>
        </div>
      </div>
    </div>
  </div>
    <Footers />
    </div>
  );
}
