import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import './productlist.css'
import NavIgationBar from '../../navbar/NavIgationBar';
import Footers from '../../footer/Footers';
import { FadeLoader } from 'react-spinners';
export default function ProductListPage() {
  const { categoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `https://desigro.languagenectar.in/api/v1/categories/products/${categoryId}`,
        {
          params: {
            limit: 10,
            offset: 0,
          },
        }
      );

      if (response.data.status && response.data.data && Array.isArray(response.data.data.products)) {
        setProducts(response.data.data.products);
      } else {
        console.error('Invalid API response structure:', response.data);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://desigro.languagenectar.in/api/v1/categories');
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  const HandleProductClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };
  const HandleSubscribeProduct = (productId) =>{
    navigate(`/subscribeproduct-details/${productId}`);
  }

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, [categoryId]);

  return (
    <div>
      <NavIgationBar/>
      {/* <div className='main-product-wise-category'>
      {loading ? (
        <div className="loader-container">
        <div className="loader">
        <FadeLoader color="#269a36" />
        </div>
      </div>
      ) : (
        <div className='text-center'>
          <h2>{categories.find(cat => cat.id === parseInt(categoryId))?.name}</h2>
          {products.length > 0 ? (
  <div className="products-card-list">
    {products.map((product) => (
      <div className="card" style={{ width: "18rem", padding: "10px" }} key={product.id}>
        <div className="product-card-image">
          <img
            src={`https://desigro.languagenectar.in/storage/app/public/product/${product.image}`}
            className="card-img-top"
            alt="..."
          />
        </div>
        <div className="card-body">
          <h5 className="card-title">{product.name}</h5>
        </div>
        <ul className="list-group list-group-flush">
          <div className="product-price">
            <s>₹{product.price}</s>
            <p>From</p>
            <p>₹{product.price - product.discounted_price}</p>
          </div>
        </ul>
        <div className="card-body">
          <div className="product-card-btns">
         
          <div class="button1" onClick={()=>HandleProductClick(product.id)} data-tooltip="">
  <div class="button-wrapper">
    <div class="text" >Buy Once</div>
    <span class="icon1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-cart2"
        viewBox="0 0 16 16"
      >
        <path
          d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
        ></path>
      </svg>
    </span>
  </div>
</div>

                    {product.category_ids && product.category_ids.is_subscribe === 1 && (
              <button  onClick={()=>HandleSubscribeProduct(product.id)} className="btn-product-card">Subscribe</button>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
) : (
  <p>No products available for the selected category.</p>
)}

        </div>
      )}
      </div> */}
       {loading ? (
        <div className="loader-container">
          <div className="loader">
            <FadeLoader color="#269a36" />
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h2>Product List</h2>
          {products.length > 0 ? (
            <div className="products-list">
              {products.map((product) => (
                <div className="product-card" key={product.id}>
                  <div className="product-image">
                    <img
                      src={`https://desigro.languagenectar.in/storage/app/public/product/${product.image}`}
                      alt="Product"
                    />
                  </div>
                  <div className="product-details">
                    <h3 className="product-name">{product.name}</h3>
                    <p className="product-price">Price: ₹{product.price}</p>
                  </div>
                  <div className="product-actions">
                    <button
                      className="view-details-btn"
                      onClick={() => HandleProductClick(product.id)}
                    >
                      Buy Once
                    </button>
                    {product.category_ids && product.category_ids.is_subscribe === 1 && (
              <button  onClick={()=>HandleSubscribeProduct(product.id)} className="subscribe-btn">Subscribe</button>
            )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No products available for the selected category.</p>
          )}
        </div>
      )}
      <Footers/>
    </div>
  );
}
