import React, { useEffect, useState } from "react";
import NavIgationBar from "../../navbar/NavIgationBar";
import Apiservice from "../../../Api/ApiController";
import Footers from "../../footer/Footers";
import { useNavigate } from "react-router-dom";
import { Data } from "@react-google-maps/api";

const DeliveryHistory = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [oredrList, setOrderList] = useState([]);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => 2100 - 100 + i); // 100 years before and after

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const navigate = useNavigate(); // Use useNavigate for navigation

  const handleMonthChange = (index) => {
    setSelectedMonth(index);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  const fetchdeliveryorderlist = async () => {
    try {
      const response = await Apiservice.orderList({
        limit: 10,
        offset: 0,
      });
      if (response.data.status === true) {
        setOrderList(response.data.data.orders);
        console.log(response.data);
      }
    } catch (error) {
      console.log("Error in fetching Api", error);
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchdeliveryorderlist();
  }, []);

  const handleSearch=()=>{
    navigate(`/deliverhistoryresult`);
  }
  return (
    <div>
      <NavIgationBar />

      {/* select date and months */}
      <div className="flex flex-col items-center gap-6 p-4">
        <h1 className="mb-4 text-xl text-[#2f8814]">Select month and year</h1>
        <div className="flex gap-10">
          {/* Month Scroller */}
          <div className="w-40 md:w-60 bg-scroll">
            <h3 className="text-center text-lg font-semibold mb-3">
              Select Month
            </h3>
            <div className="h-48 overflow-y-auto border rounded-lg shadow-lg">
              {months.map((month, index) => (
                <div
                  key={month}
                  className={`p-3 cursor-pointer text-center transition ${
                    index === selectedMonth
                      ? "bg-[#2f8814] text-white font-bold"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => handleMonthChange(index)}
                >
                  {month}
                </div>
              ))}
            </div>
          </div>

          {/* Year Scroller */}
          <div className="w-40 md:w-60 bg-scroll">
            <h3 className="text-center text-lg font-semibold mb-3">
              Select Year
            </h3>
            <div className="h-48 overflow-y-auto border rounded-lg shadow-lg">
              {years.map((year) => (
                <div
                  key={year}
                  className={`p-3 cursor-pointer text-center transition ${
                    year === selectedYear
                      ? "bg-[#2f8814] text-white font-bold"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => handleYearChange(year)}
                >
                  {year}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Selected Value */}
        <div className="text-lg font-medium mt-10">
          Selected:{" "}
          <span className="text-[#2f8814]">{months[selectedMonth]}</span>{" "}
          <span className="text-[#2f8814]">{selectedYear}</span>
        </div>

        {/* Search Button */}
        <div>
          <button
            className="bg-[#2f8814] text-white px-20 py-2 rounded text-lg font-semibold"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
      </div>

      <Footers />
    </div>
  );
};

export default DeliveryHistory;
