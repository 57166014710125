import React, { useEffect, useState } from 'react';
import './notification.css'; // Import your CSS file for styling
import Apiservice from '../../../Api/ApiController';
import Navbar from '../../navbar/NavIgationBar';
import Footers from '../../footer/Footers';


export default function Notification() {
    const [Notifications,setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage] = useState(10);
    const URL =  "https://desigro.languagenectar.in/storage/app/public/product/";
    const getnotification = async(page)=>{
       await Apiservice.notification({
        page
        }).then((response)=>{
            if(response.data.status === true){
              setNotifications(response.data.data)
              console.log(response.data)
              setTotalPages(response.data.total_pages);
            }else{
              if(currentPage > 1){
                setCurrentPage(currentPage -1)
               }else{
        
               }
            }
        }).catch((err)=>{
          console.log(err)
        })
    }
       useEffect(()=>{
        getnotification(currentPage)
       },[currentPage]);


       const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      };

      const clearnotification = ()=>{
        Apiservice.ClearNotification().then((response)=>{
            if(response.data.status === true){
             console.log(response.data)
             getnotification()
            }else{
                console.error(response)
            }
        }).catch((err)=> console.error(err))
      }
  return (
    <>
    <Navbar/>
    <div className="notification-container">
        <div onClick={clearnotification} className='clear-notification'>
            Clear Notification
        </div>
      <section className="notification-section">
        {Notifications.map((notification,index)=>(
            
            <div className="notification-list" key={index}>
          {/* Individual notification items */}
          <div className="notification-item">
            <div className="notification-content">
              <div>
                <img src={URL + notification.data.image} alt="" />
              </div>
              {/* Notification title and message */}
              <h3 className="notification-title">{notification.data.title}</h3>
              <p className="notification-message">{notification.data.description}</p>
            </div>
          </div>
          {/* Add more notification items as needed */}
        </div>
            ))}
      </section>
      {/* <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
            &#8249;
        </button>
        <span>
          {currentPage} 
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
                &#8250;
        </button>
      </div> */}
    </div>
    <Footers/>
          </>
  );
}
