import React, { useEffect, useState } from "react";
import Footers from "../../footer/Footers";
import {
  QuantitySelector,
  capitalizeFirstLetter,
} from "../../helper/QuantitySelector";
import NavIgationBar from "../../navbar/NavIgationBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Apiservice from "../../../Api/ApiController";
import "./subscribeproduct.css";
import SuccessMessage from './SuccessMessage';  // Import the SuccessMessage component
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function SubscribeProductDetails() {
  const [intervalQuantities, setIntervalQuantities] = useState(1);
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [frequency, setFrequency] = useState("");
  const [intervalValue, setIntervalValue] = useState("");
  const [customInterval, setCustomInterval] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [altquantity, setaltquantity] = useState(0);

  const [addresslist, setaddresslist] = useState([]);
  const [selectedaddress, setSelectedAddressId] = useState();
  const [orderTotal, setorderTotal] = useState(0);
  // const [intervalQuantity, setIntervalQuantity] = useState(0);
  const [productquantity, setproductquantity] = useState(0);
  const [customQuantities, setCustomQuantities] = useState({
    sunday: 0,
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
  });
  const navigate = useNavigate();
  const [isSubscribe, setIsSubscribe] = useState(false);
  const token = localStorage.getItem("authtoken");
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [customarray, setcustomarray] = useState("");
  const [subscriptionMessage, setSubscriptionMessage] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const productResponse = await axios.get(
          `https://desigro.languagenectar.in/api/v1/services/details/${productId}`
        );
        setProductDetails(productResponse.data.data);

        const categoryId = productResponse.data.data.category_id;
        const categoriesResponse = await axios.get(
          `https://desigro.languagenectar.in/api/v1/categories/products/${categoryId}`,
          {
            params: {
              limit: 10,
              offset: 0,
            },
          }
        );

        const isSubscribe =
          categoriesResponse.data.data.products &&
          categoriesResponse.data.data.products.length > 0
            ? categoriesResponse.data.data.products[0].category_ids
                .is_subscribe === 1
            : false;

        setIsSubscribe(isSubscribe);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [productId]);

  const UpdateDate = () => {
    setendDate(startDate);

    if (endDate < startDate) {
      setendDate("");
    }
  };

  useEffect(() => {
    calculateOrderTotal();
  });

  const calculateOrderTotal = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let totalQuantity = 0;
    switch (frequency) {
      case "everyday":
        totalQuantity = quantity + altquantity * Math.max(diffDays - 1, 0);

        break;
      case "interval":
        if (intervalValue === "custome") {
          const customIntervalDays = parseInt(customInterval);
          if (!isNaN(customIntervalDays) && customIntervalDays > 0) {
            const numIntervals = Math.floor(diffDays / customIntervalDays);
            console.log(numIntervals);
            totalQuantity = intervalQuantities * numIntervals;
          }
        } else {
          const intervalDays = parseInt(intervalValue);
          if (!isNaN(intervalDays) && intervalDays > 0) {
            const numIntervals = Math.floor(diffDays / intervalDays);
            totalQuantity = intervalQuantities * numIntervals;
            console.log(numIntervals);
          }
        }
        break;
      case "custom":
        const quantitiesByDay = {
          sunday: 0,
          monday: 0,
          tuesday: 0,
          wednesday: 0,
          thursday: 0,
          friday: 0,
          saturday: 0,
        };

        const processedDates = new Set();

        let currentDate = new Date(startDate);
        const end = new Date(endDate);

        while (currentDate <= end) {
          const currentDay = currentDate.getDay();

          const currentDateKey = currentDate.toISOString().split("T")[0];
          if (!processedDates.has(currentDateKey)) {
            const currentDayKey = Object.keys(quantitiesByDay)[currentDay];
            if (
              currentDayKey &&
              customQuantities[currentDayKey] !== undefined
            ) {
              quantitiesByDay[currentDayKey] += customQuantities[currentDayKey];
            }

            processedDates.add(currentDateKey);
          }

          currentDate.setDate(currentDate.getDate() + 1);
        }

        totalQuantity = Object.values(quantitiesByDay).reduce(
          (acc, val) => acc + val,
          0
        );
        console.log(totalQuantity);
        break;
      default:
        break;
    }
    let pricePerUnit = 0;
    if (productDetails.discount_type === "percent") {
      pricePerUnit =
        productDetails.price -
        productDetails.price * (productDetails.discount / 100);
    } else {
      pricePerUnit = productDetails.price - productDetails.discount;
    }
    const totalPrice = totalQuantity * pricePerUnit;

    setorderTotal(totalPrice);
  };

  const HandleIncrease = (type, day, e) => {
    if (e) {
      e.preventDefault();
    }

    switch (type) {
      case "everyday":
        // Update quantity
        setQuantity((prevQuantity) => prevQuantity + 1);
        break;
      case "interval":
        setIntervalQuantities(
          (prevIntervalQuantity) => prevIntervalQuantity + 1
        );
        break;
      case "custom":
        setCustomQuantities((prevCustomQuantities) => ({
          ...prevCustomQuantities,
          [day]: prevCustomQuantities[day] + 1,
        }));
        break;
      default:
        break;
    }
  };

  const HandleDecrease = (type, day, e) => {
    if (e) {
      e.preventDefault();
    }

    switch (type) {
      case "everyday":
        // Update quantity
        setQuantity((prevQuantity) =>
          prevQuantity > 1 ? prevQuantity - 1 : 0
        );
        break;
      case "interval":
        setIntervalQuantities((prevIntervalQuantity) =>
          prevIntervalQuantity > 1 ? prevIntervalQuantity - 1 : 0
        );
        break;
      case "custom":
        setCustomQuantities((prevCustomQuantities) => ({
          ...prevCustomQuantities,
          [day]:
            prevCustomQuantities[day] >= 1 ? prevCustomQuantities[day] - 1 : 0,
        }));
        break;
      default:
        break;
    }
  };

  const HandleAltIncrease = (e) => {
    e.preventDefault();
    setaltquantity((prevAltQuantity) => prevAltQuantity + 1);
  };

  const HandleAltDecrease = (e) => {
    e.preventDefault();

    setaltquantity((prevAltQuantity) =>
      prevAltQuantity > 1 ? prevAltQuantity - 1 : 1
    );
  };

  const handleIntervalChange = (event) => {
    const value = event.target.value;
    setIntervalValue(value);
    setIntervalQuantities(1);
    if (value === "custom") {
      setCustomInterval("");
      setIntervalQuantities(1);
    }
  };

  const ProductQuantityIncrease = (e) => {
    e.preventDefault();
    setproductquantity(productquantity + 1);
  };
  const ProductQuantityDecrease = (e) => {
    e.preventDefault();
    if (productquantity >= 0) {
      setproductquantity(productquantity - 1);
    }
  };

  const Subscription = async (e) => {
    e.preventDefault();

    try {
      let selectedQuantity;
      let days = [];
      let qty = [];
      const start = new Date(startDate);
      const end = new Date(endDate);
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Calculate the difference in days
      let customArray;
      if (frequency === "custom") {
        qty.reduce((acc, val) => acc + val, 0);
        customArray = Object.keys(customQuantities).map((day) => ({
          day,
          qty: customQuantities[day],
        }));
      }

      if (isSubscribe) {
        // Handle subscription scenarios (everyday, interval, custom)
        switch (frequency) {
          case "everyday":
            selectedQuantity = quantity;
            days = "Everyday";
            qty = quantity;
            break;
          case "interval":
            if (intervalValue === "custome") {
              // Handle custom interval case
              const customIntervalDays = parseInt(customInterval);
              if (!isNaN(customIntervalDays) && customIntervalDays > 0) {
                const numIntervals = Math.floor(diffDays / customIntervalDays);
                days = `Every ${customIntervalDays} days`;
                qty = intervalQuantities;
                selectedQuantity = intervalQuantities * numIntervals;
              }
            } else {
              // Handle other interval cases (e.g., "Every 2 days", "Every 3 days", etc.)
              const intervalDay = parseInt(intervalValue);
              if (!isNaN(intervalDay) && intervalDay > 0) {
                const numIntervals = Math.floor(diffDays / intervalDay);
                days = `Every ${intervalDay} days`;
                qty = intervalQuantities;

                selectedQuantity = qty;
              }
            }
            break;

          case "custom":
            days = Object.keys(customQuantities).map((day) =>
              capitalizeFirstLetter(day)
            );
            qty = Object.values(customQuantities);
            selectedQuantity = qty.reduce((acc, val) => acc + val, 0);
            break;
          default:
            selectedQuantity = 0;
            break;
        }
      } else {
        selectedQuantity = productquantity;
      }

      if (!selectedaddress) {
        Swal.fire({
          icon: "info",
          text: "Please Select Address",
        });
      }
      if (!selectedOption) {
        Swal.fire({
          icon: "info",
          text: "Please Select Delivery Instruction",
        });
      } else {
        const response = await Apiservice.Placeorder({
          order_amount: orderTotal,
          payment_method: "wallet",
          is_subscribe: 1,
          subscription_type: frequency,
          address: selectedaddress,
          region_id: selectedaddress.region_id,
          city_id: selectedaddress.city_id,
          due_amount: orderTotal,
          qty: JSON.stringify(qty),
          days: JSON.stringify(days),
          mode: "debit",
          subscription_date: startDate,
          subscription_end: endDate,
          service_id: productId,
          alternate_quantity: altquantity,
          custom_array: customArray,
        });
        if (response.data.status === true) {
          console.log(response.data);
          Swal.fire({
            title: "Subscribed Successfully",
            icon: "success",
            showConfirmButton: false,
            showCancelButton: false,
            html: `
            <p> Congratulations You have successfully Subscribed selected product. Thank You for an opportunity to serve you Best.</p>
            <p>Note : We recommend a recharge of Rs. ${orderTotal} to continue with your subscription for next ${diffDays} Days</p>`,
            footer: `
                <div class="Add-money-btn">
                <a  href="/wallet">Add Money</a>
                </div>`,
          });
          // Show the toast notification with the message
          toast.success(
            <div>
              <p>successfully Subscribed</p>
            </div>,
            {
              position: "top-right",
              autoClose: 1000, // Duration for toast to stay visible
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.message,
          });
        }
      }
    } catch (error) {
      console.error("Error placing the order:", error);
      // Handle the error as needed
    }

    setIsSubscribe(true); // Set to true to show success message
    setSubscriptionMessage("Congratulations! You've successfully subscribed!");

    // Hide the success message after 5 seconds (optional)
    setTimeout(() => {
      setIsSubscribe(false); // Reset the subscription status
      setSubscriptionMessage(""); // Clear the success message
    }, 5000); // You can change the duration
  };

  const fetchAddresses = async () => {
    try {
      const response = await Apiservice.FetchAddresslist();

      if (response.data.status === true) {
        setaddresslist(response.data.data);
        console.log(response.data.data);
      } else {
        console.error("Error fetching addresses:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }

  
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const today = new Date();
    // const formattedDate = formatDate(today)
    today.setDate(today.getDate() + 1);
    const formattedDate = formatDate(today);
    setstartDate(formattedDate);

    const onemonth = today;
    onemonth.setMonth(onemonth.getMonth() + 1);
    const onemonthformat = formatDate(onemonth);
    setendDate(onemonthformat);
  }, []);
  const handleRadioChange = (option) => {
    setSelectedOption(option === selectedOption ? null : option);
  };

  const editaddress = (addressid) => {
    navigate(`/editaddress/${addressid}`);
  };
  return (
    <div>
      <NavIgationBar />
      <div style={{ paddingBottom: "15px" }}>
        <section className="detailsPage">
          <div className="breadcrumbWrapper">
            <div className="container-fluid ">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/shop-all">Shop All</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Product Details
                </li>
              </ol>
            </div>
          </div>

          <div className="container-fluid ">
            <div className="row">
              <div className="col-md-9 part1">
                <div className="row">
                  <div className="col-md-6">
                    <div className="productZoom">
                      <img
                        src={
                          productDetails && productDetails.image
                            ? `https://desigro.languagenectar.in/storage/app/public/product/${productDetails.image}`
                            : ""
                        }
                        alt="Product"
                        className="product-details-image"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 productInfo">
                    <h1>{productDetails.name}</h1>
                    <div className="d-flex align-items-center mb-4">
                      {Array.from({
                        length: Math.floor(productDetails.avg_rating),
                      }).map((_, index) => (
                        <box-icon
                          key={index}
                          name="star"
                          type="solid"
                          flip="horizontal"
                          color="#f6a203"
                        ></box-icon>
                      ))}
                    </div>
                    <div className="priceSec">
                      {productDetails.discount_type === "percent" ? (
                        <>
                          <span>
                            ₹
                            {productDetails.price -
                              productDetails.price *
                                (productDetails.discount / 100)}
                          </span>
                          <span className="discount">
                            ({productDetails.discount}% off)
                          </span>
                        </>
                      ) : (
                        <>
                          <span>
                            ₹{productDetails.price - productDetails.discount}
                          </span>
                          <span className="discount">
                            (₹{productDetails.discount} off)
                          </span>
                        </>
                      )}
                      <s className="olderPrice">₹{productDetails.price}</s>
                    </div>
                    <h5>Product Description</h5>
                    <p>{productDetails.description}</p>
                    <form id="product-form">
                      {isSubscribe ? (
                        <>
                          <label htmlFor="">Start date</label>
                          <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setstartDate(e.target.value)}
                            onBlur={UpdateDate}
                          />
                          <label htmlFor="">End date</label>
                          <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setendDate(e.target.value)}
                            min={startDate}
                            placeholder="Optional"
                          />
                        </>
                      ) : null}
                      {isSubscribe ? (
                        <div className="form-group">
                          <label htmlFor="frequency">Frequency</label>
                          <select
                            className="form-select"
                            name="frequency"
                            id="frequency"
                            value={frequency}
                            onChange={(e) => setFrequency(e.target.value)}
                          >
                            <option selected disabled value="">
                              Select Frequency
                            </option>
                            <option value="everyday">Everyday</option>
                            <option value="interval">On Interval</option>
                            <option value="custom">Custom</option>
                          </select>
                        </div>
                      ) : (
                        <div className="productquantity-1">
                          <button
                            className="productquantity-btn-1"
                            onClick={ProductQuantityDecrease}
                          >
                            <span>-</span>
                          </button>
                          <input
                            className="productquantity-input"
                            type="text"
                            value={productquantity}
                          />
                          <button
                            className="productquantity-btn-2"
                            onClick={ProductQuantityIncrease}
                          >
                            <span>+</span>
                          </button>
                        </div>
                      )}

                      {isSubscribe && frequency === "everyday" && (
                        <>
                          Day 1 :{" "}
                          <QuantitySelector
                            quantity={quantity}
                            onDecrease={(e) =>
                              HandleDecrease("everyday", null, e)
                            }
                            onIncrease={(e) =>
                              HandleIncrease("everyday", null, e)
                            }
                          />
                          Day 2 :{" "}
                          <QuantitySelector
                            quantity={altquantity}
                            onDecrease={(e) => HandleAltDecrease(e)}
                            onIncrease={(e) => HandleAltIncrease(e)}
                          />
                        </>
                      )}

                      {frequency === "interval" && (
                        <div>
                          <label htmlFor="interval">Select Interval</label>
                          <select
                            className="form-select"
                            id="interval"
                            name="interval"
                            value={intervalValue}
                            onChange={handleIntervalChange}
                          >
                            <option disabled selected value="">
                              Select Interval
                            </option>
                            <option value="2">Every 2 days</option>
                            <option value="3">Every 3 days</option>
                            <option value="4">Every 4 days</option>
                            <option value="5">Every 5 days</option>
                            <option value="custome">Every nth Days</option>
                          </select>
                          <br />

                          {intervalValue >= 2 && intervalValue <= 5 && (
                            <QuantitySelector
                              quantity={intervalQuantities}
                              onDecrease={(e) =>
                                HandleDecrease("interval", null, e)
                              }
                              onIncrease={(e) =>
                                HandleIncrease("interval", null, e)
                              }
                            />
                          )}

                          {intervalValue === "custome" && (
                            <div>
                              <label htmlFor="customInterval">
                                Custom Interval:
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ margin: "0 5px" }}>Every</span>
                                <input
                                  type="text"
                                  id="customIntervalLeft"
                                  style={{ marginRight: "5px", width: "50px" }}
                                  value={customInterval.left}
                                  onChange={(e) =>
                                    setCustomInterval(e.target.value)
                                  }
                                />
                                <span style={{ margin: "0 5px" }}>Days</span>
                              </div>
                              <label htmlFor="customQuantity">
                                Custom Quantity:
                              </label>
                              <QuantitySelector
                                quantity={intervalQuantities} // Assuming you want to use the same quantity for custom as well
                                onDecrease={(e) =>
                                  HandleDecrease("interval", null, e)
                                }
                                onIncrease={(e) =>
                                  HandleIncrease("interval", null, e)
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}

                      {frequency === "custom" && (
                        <div>
                          {Object.keys(customQuantities).map((day) => (
                            <div key={day}>
                              <label htmlFor={day}>
                                {capitalizeFirstLetter(day)}:
                              </label>
                              <QuantitySelector
                                quantity={customQuantities[day]}
                                onDecrease={(e) =>
                                  HandleDecrease("custom", day, e)
                                }
                                onIncrease={(e) =>
                                  HandleIncrease("custom", day, e)
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}

                      <br />

                      <div className="address-selection">
                        <h2>Select Address</h2>
                        <div className="address-cards">
                          {addresslist.map((address, index) => (
                            <div
                              className={`address-card ${
                                selectedaddress &&
                                selectedaddress.id === address.id
                                  ? "selected"
                                  : ""
                              }`}
                              key={index}
                              onClick={() => setSelectedAddressId(address)}
                            >
                              <div className="edit-address-section">
                                <h3>{address.address_type}</h3>
                                <span onClick={() => editaddress(address.id)}>
                                  <i class="bx bx-edit-alt"></i>
                                </span>
                              </div>
                              <p>
                                {address.address}, {address.location}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <br />
                      <section>
                        <section className="delivery-instruction-section">
                          <div>
                            <h6>Delivery Instruction</h6>
                          </div>
                          <div className="delivery-instruction-1">
                            <label
                              htmlFor="ringDoorBell"
                              className={`delivery-instruction-sub-1 ${
                                selectedOption === "Ring Door bell"
                                  ? "color-green"
                                  : ""
                              }`}
                            >
                              <i class="bx bx-bell"></i>
                              Ring Door bell
                            </label>
                            <div className="delivery-instruction-sub-2">
                              <input
                                id="ringDoorBell"
                                type="radio"
                                name="delivery_instruction"
                                checked={selectedOption === "Ring Door bell"}
                                onChange={() =>
                                  handleRadioChange("Ring Door bell")
                                }
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="delivery-instruction-1">
                            <label
                              htmlFor="DropatTheDoor"
                              className={`delivery-instruction-sub-1 ${
                                selectedOption === "Drop at the Door"
                                  ? "color-green"
                                  : ""
                              }`}
                            >
                              <i class="bx bx-door-open"></i>
                              Drop at the Door
                            </label>
                            <div className="delivery-instruction-sub-2">
                              <input
                                id="DropatTheDoor"
                                type="radio"
                                name="delivery_instruction"
                                checked={selectedOption === "Drop at the Door"}
                                onChange={() =>
                                  handleRadioChange("Drop at the Door")
                                }
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="delivery-instruction-1">
                            <label
                              htmlFor="InHandDelivery"
                              className={`delivery-instruction-sub-1 ${
                                selectedOption === "In Hand Delivery"
                                  ? "color-green"
                                  : ""
                              }`}
                            >
                              <i class="bx bxs-hand"></i>
                              In Hand Delivery
                            </label>
                            <div className="delivery-instruction-sub-2">
                              <input
                                id="InHandDelivery"
                                type="radio"
                                name="delivery_instruction"
                                checked={selectedOption === "In Hand Delivery"}
                                onChange={() =>
                                  handleRadioChange("In Hand Delivery")
                                }
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="delivery-instruction-1">
                            <label
                              htmlFor="KeepInBag"
                              className={`delivery-instruction-sub-1 ${
                                selectedOption === "Keep In Bag"
                                  ? "color-green"
                                  : ""
                              }`}
                            >
                              <i class="bx bxs-shopping-bag"></i>
                              Keep In Bag
                            </label>
                            <div className="delivery-instruction-sub-2">
                              <input
                                id="KeepInBag"
                                type="radio"
                                name="delivery_instruction"
                                checked={selectedOption === "Keep In Bag"}
                                onChange={() =>
                                  handleRadioChange("Keep In Bag")
                                }
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="delivery-instruction-1">
                            <label
                              htmlFor="NoPreferences"
                              className={`delivery-instruction-sub-1 ${
                                selectedOption === "No Preferences"
                                  ? "color-green"
                                  : ""
                              }`}
                            >
                              <i class="bx bxs-cog"></i>
                              No Preferences
                            </label>
                            <div className="delivery-instruction-sub-2">
                              <input
                                id="NoPreferences"
                                type="radio"
                                name="delivery_instruction"
                                checked={selectedOption === "No Preferences"}
                                onChange={() =>
                                  handleRadioChange("No Preferences")
                                }
                              />
                            </div>
                          </div>
                          <hr />
                        </section>
                        <section className="additional-instruction-cart">
                          <h6>Additional Instruction</h6>
                          <input type="text" placeholder="optional" />
                        </section>
                      </section>
                      <button
                        onClick={Subscription}
                        className="button-product-details"
                      >
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-3 part2"></div>
            </div>
          </div>
        </section>
      </div>
      {/* Show the SuccessMessage component conditionally */}
      <ToastContainer />
      {/* Display success message after subscription */}
      <Footers />
    </div>
  );
}
