import React, { useEffect, useState } from "react";
import Apiservice from "../../../../Api/ApiController";
import Footers from "../../../footer/Footers";
import Navbar from "../../../navbar/NavIgationBar";
import "./editaddress.css";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
export default function EditAddress() {
  const [regions, setRegions] = useState([]);
  const [selectedRegionId, setSelectedRegionId] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [cities, setCities] = useState([]);
  const [name, setName] = useState("");
  const [addressType, setAddressType] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
//   const [addressList, setAddressList] = useState([]);

  const [landmark, setlandmark] = useState("");
  const [Pincode, setPincode] = useState("");
  const [floor, setfloor] = useState("");
  const [flatnumber, setflatnumber] = useState("");
  const token = sessionStorage.getItem("authtoken");

  const addresId = useParams();
  console.log(addresId);
  useEffect(() => {
    Apiservice.FetchAddresslist()
      .then((response) => {
        if (response.data.status === true) {
          const addressList = response.data.data;
          console.log(parseInt(addresId.addresID));
          const addressbyId = addressList.find(
            (address) => address.id === parseInt(addresId.addresID)
          );
          if (addressbyId) {
            console.log(addressbyId);
            setlandmark(addressbyId.land_mark)
            setLocation(addressbyId.location)
            setAddress(addressbyId.address)
            setPincode(addressbyId.pin_code)
            setfloor(addressbyId.floor)
            setflatnumber(addressbyId.flat_no)
            setSelectedCityId(addressbyId.city_id)
            setSelectedRegionId(addressbyId.region_id)
            setAddressType(addressbyId.address_type)
          } else {
            console.error("Address not found");
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const fetchRegions = async () => {
    try {
      const response = await Apiservice.ZoneList();
      if (
        response.data &&
        response.data.status &&
        Array.isArray(response.data.data)
      ) {
        setRegions(response.data.data);
      } else {
        console.error("Invalid API response format");
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };
  useEffect(() => {
    fetchRegions();
  }, []);
  const fetchCities = async (regionId) => {
    console.log(regionId);
    try {
      const response = await Apiservice.citylist(regionId);
      if (response.data.status === true) {
        setCities(response.data.data);
      } else {
        console.error("Invalid API response format");
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleUpdateAddress = async (e) => {
    e.preventDefault()
    try {
      const response = await Apiservice.AddressAdd({
        address_type: addressType,
        address: address,
        region_id: selectedRegionId,
        city_id: selectedCityId,
        location: location,
        land_mark: landmark,
        flat_no: flatnumber,
        id: addresId,
        pin_code: Pincode,
        floor:floor
      });
      if (response.data.status === true) {
        Swal.fire({
          icon: "success",
          text: response.data.message,
        });
      } else {
        console.error(
          "Failed to update address:",
          response.data.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error updating address:", error);
    }
  };

  const handleRegionChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setSelectedRegionId(selectedId);
    fetchCities(selectedId);
  };

  const handlecityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCityName =
      event.target.options[event.target.selectedIndex].text;

    setSelectedCityId(selectedCityId);
    setLocation(selectedCityName); 
  };
  return (
    <>
      <Navbar />
      <section className="edit-address-section-main">
        <form
          action="#"
          onSubmit={handleUpdateAddress}
          className="needs-validation"
          noValidate
        >
          <div className="first_inputs_editaddress">
            <div className="first_inputs_editaddress_rigion">
              <label htmlFor="regionDropdown">Select Region:</label>
              <select
                id="regionDropdown"
                onChange={handleRegionChange}
                value={selectedRegionId || ""}
                className="form-select"
              >
                <option value="" disabled>
                  Select a region
                </option>

                {regions.map((region) => (
                  <option key={region.id} value={region.id}>
                    {region.name}
                  </option>
                ))}
              </select>
            </div>
     
            <div className="first_inputs_editaddress_rigion">
              <label htmlFor="cityDropdown">Select City:</label>
              <select
                id="cityDropdown"
                onChange={handlecityChange}
                className="form-select"
              >
                <option value="" selected disabled>
                  Select a location
                </option>
                {cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">
                Please enter your location.
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="addressType" className="form-label">
              Address Type
            </label>
            <input
              type="text"
              className="form-control"
              id="addressType"
              value={addressType}
              onChange={(e) => setAddressType(e.target.value)}
              placeholder="e.g., Home, Work"
              required
            />
            <div className="invalid-feedback">
              Please enter the address type.
            </div>
          </div>
          <div className="first_inputs_editaddress">
            <div className="first_inputs_editaddress_flat">
              <label htmlFor="name" className="form-label">
                Flat Number/Office Unit
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={flatnumber}
                onChange={(e) => setflatnumber(e.target.value)}
                placeholder="Flat Number/Office Unit"
                required
              />
              <div className="invalid-feedback">
                Please enter your flat number.
              </div>
            </div>

            <div className="first_inputs_editaddress_flat">
              <label htmlFor="Floor" className="form-label">
                Floor
              </label>
              <input
                type="tel"
                className="form-control"
                id="Floor"
                value={floor}
                onChange={(e) => setfloor(e.target.value)}
                placeholder="Floor"
                required
              />
              <div className="invalid-feedback">
                Please enter a valid mobile number.
              </div>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="address" className="form-label">
              Address
            </label>
            <textarea
              className="form-control"
              id="address"
              placeholder="Enter your address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              rows="3"
              required
            ></textarea>
            <div className="invalid-feedback">Please enter your address.</div>
          </div>
          <div className="mb-3">
            <label htmlFor="Landmark" className="form-label">
              Landmark
            </label>
            <input
              type="tel"
              className="form-control"
              id="Landmark"
              value={landmark}
              onChange={(e) => setlandmark(e.target.value)}
              placeholder="Landmark"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="Pincode" className="form-label">
              Pincode*
            </label>
            <input
              type="tel"
              className="form-control"
              id="Pincode"
              value={Pincode}
              onChange={(e) => setPincode(e.target.value)}
              placeholder="Pincode"
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Save Address
          </button>
        </form>
      </section>
      <Footers />
    </>
  );
}
