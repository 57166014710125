import React, { useEffect, useState } from "react";
import "./subscription.css";
import axios from "axios";
import Swal from "sweetalert2";

import { QuantitySelector, capitalizeFirstLetter } from "../helper/QuantitySelector";
import { useParams } from "react-router-dom";
import Apiservice from "../../Api/ApiController";
import Navbar from "../navbar/NavIgationBar";
import Footers from "../footer/Footers";
const SubscriptionList = () => {
  const [Subscribe, setSubscribe] = useState([]);
  const token = sessionStorage.getItem("authtoken");
  const [intervalQuantities, setIntervalQuantities] = useState(0);
  // const { productId } = useParams();
  // const [SubscribeId , setSubscribeId]= useState()
  const [productDetails, setProductDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [frequency, setFrequency] = useState("everyday");
  const [intervalValue, setIntervalValue] = useState("");
  const [customInterval, setCustomInterval] = useState("");
  const [quantity, setQuantity] = useState(0);
  
  const [orderTotal, setorderTotal] = useState(0)
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [productquantity, setproductquantity] = useState(0);
  const [altquantity, setaltquantity] = useState();
  const [customQuantities, setCustomQuantities] = useState({
    sunday: 0,
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
  });

  const [isSubscribe, setIsSubscribe] = useState(false);
  const [selectedorderId, setSelectedOrderId] = useState(null);

  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [vacationDate, setvacationDate] = useState(new Date());
  const [endvacationDate, setEndvacationDate] = useState(null);
  useEffect(()=>{
    calculateOrderTotal()
  })

  const calculateOrderTotal = () => {
    // Step 1: Calculate the number of days between start and end dates
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 23));
  
    // Step 2: Based on the frequency, calculate the total quantity for each interval
    let totalQuantity = 0;
    switch (frequency) {
      case "everyday":
        totalQuantity = quantity + altquantity * Math.max(diffDays - 1, 0) -1; 
     
        break;
        case "interval":
      if (intervalValue === "custome") {
        const customIntervalDays = parseInt(customInterval);
        if (!isNaN(customIntervalDays) && customIntervalDays > 0) {
          const numIntervals = Math.floor(diffDays / customIntervalDays);
          totalQuantity = intervalQuantities * numIntervals;
        }
      } else {
        const intervalDays = parseInt(intervalValue);
        if (!isNaN(intervalDays) && intervalDays > 0) {
          const numIntervals = Math.floor(diffDays / intervalDays);
          totalQuantity = intervalQuantities * numIntervals;
        }
   
      }
      break;
      case "custom":
        const quantitiesByDay = {
          sunday: 0,
          monday: 0,
          tuesday: 0,
          wednesday: 0,
          thursday: 0,
          friday: 0,
          saturday: 0,
        };
        
        // Create a Set to keep track of processed dates
        const processedDates = new Set();
        
        // Iterate through each day between start and end dates
        let currentDate = new Date(startDate);
        const end = new Date(endDate);
        
        while (currentDate <= end) {
          const currentDay = currentDate.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        
          // Check if the current date has already been processed
          const currentDateKey = currentDate.toISOString().split('T')[0];
          if (!processedDates.has(currentDateKey)) {
            // Add the quantity specified for the current day to the map
            const currentDayKey = Object.keys(quantitiesByDay)[currentDay];
            if (currentDayKey && customQuantities[currentDayKey] !== undefined) {
              quantitiesByDay[currentDayKey] += customQuantities[currentDayKey];
            }
        
            // Add the current date to the set of processed dates
            processedDates.add(currentDateKey);
          }
        
          // Move to the next day
          currentDate.setDate(currentDate.getDate() + 1);
          
        }
        
        // Sum up the quantities for all days
        totalQuantity = Object.values(quantitiesByDay).reduce((acc, val) => acc + val, 0);
        console.log(totalQuantity);
        break;
      default:
        break;
    }
  
    // Step 3: Multiply the total quantity by the price per unit to get the total price
    const pricePerUnit = productDetails.price
    // console.log(productDetails.price); // Example price per unit
    const totalPrice = totalQuantity * pricePerUnit;
  
    // Step 4: Set the order total
    setorderTotal(totalPrice);

  };
  

  const HandleIncrease = (type, day, e) => {
    if (e) {
      e.preventDefault();
    }

    switch (type) {
      case "everyday":
        setQuantity((prevQuantity) => prevQuantity + 1);
        break;
      case "interval":
        setIntervalQuantities(
          (prevIntervalQuantity) => prevIntervalQuantity + 1
        );
        break;
      case "custom":
        setCustomQuantities((prevCustomQuantities) => ({
          ...prevCustomQuantities,
          [day]: prevCustomQuantities[day] + 1,
        }));
        break;
      default:
        break;
    }
  };

  const HandleDecrease = (type, day, e) => {
    if (e) {
      e.preventDefault();
    }

    switch (type) {
      case "everyday":
        setQuantity((prevQuantity) =>
          prevQuantity >= 1 ? prevQuantity - 1 : 0
        );
        break;
      case "interval":
        setIntervalQuantities((prevIntervalQuantity) =>
          prevIntervalQuantity > 1 ? prevIntervalQuantity - 1 : 0
        );
        break;
      case "custom":
        setCustomQuantities((prevCustomQuantities) => ({
          ...prevCustomQuantities,
          [day]:
            prevCustomQuantities[day] >= 1 ? prevCustomQuantities[day] - 1 : 0,
        }));
        break;
      default:
        break;
    }
  };

  const handleIntervalChange = (event) => {
    const value = event.target.value;
    setIntervalValue(value);
    if (value === "custom") {
      setCustomInterval("");
    }
  };

  const HandleAltIncrease = (e) => {
    e.preventDefault();
    setaltquantity((prevAltQuantity) => prevAltQuantity + 1);
  };
  
  const HandleAltDecrease = (e) => {
    e.preventDefault();
 
    setaltquantity((prevAltQuantity) => (prevAltQuantity > 1 ? prevAltQuantity - 1 : 1));
  };
  
  const ProductQuantityIncrease = (e) => {
    e.preventDefault();
    setproductquantity(productquantity + 1);
  };
  const ProductQuantityDecrease = (e) => {
    e.preventDefault();
    if (productquantity >= 0) {
      setproductquantity(productquantity - 1);
    }
  };

  const subscriptionProducts = async () => {
    try {
      // const response = await axios.get(
      //   "https://desigro.languagenectar.in/api/v1/customer/order/get-subscription",
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      const response =  await Apiservice.SubscriptionList()
      console.log(response)
      if (response.data.status === true) {
               
        setSubscribe(response.data.data);
     
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    subscriptionProducts();
  }, []);

  const UpdateDate = () => {
    setendDate(startDate);

    if (endDate < startDate) {
      setendDate("");
    }
  };
  const handleEditSubscription = (product) => {
    // -------------------------------------------
    const subscriptionStartDate = product.subscription_date.split(' ')[0];
    setstartDate(subscriptionStartDate);
    const subscriptionEndDate = product.subscription_end.split(' ')[0];
    setendDate(subscriptionEndDate);
// ----------------------------------------------------------------
    setFrequency(product.subscription_type);
    const intervalMatch = product.days.match(/\d+/);
    const intervalValue = intervalMatch ? parseInt(intervalMatch[0]) : null;
    setIntervalValue(intervalValue !== null ? intervalValue.toString() : ""); 
// =======================================================================
    const quantityValue = parseInt(product.qty[0].replace(/\D/g, ''), 10);
    setQuantity(quantityValue);
    setIntervalQuantities(quantityValue);
    setaltquantity(product.alt_qty)
 // =======================================================================
    const customQuantityValues = product.qty.map(qtyString => parseInt(qtyString.replace(/\D/g, ''), 10));
    const customQuantitiesObj = {
        sunday: customQuantityValues[0],
        monday: customQuantityValues[1],
        tuesday: customQuantityValues[2],
        wednesday: customQuantityValues[3],
        thursday: customQuantityValues[4],
        friday: customQuantityValues[5],
        saturday: customQuantityValues[6]
    };
    setCustomQuantities(customQuantitiesObj);
    // =======================================================================
    setSelectedSubscription(product)
};


  
const DeleteSubscription = async (SubscribeId)=>{
  // console.log(SubscribeId)
  const response = await axios.delete(`https://desigro.languagenectar.in/api/v1/customer/order/delete-subscription?id=${SubscribeId}`,{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  console.log(response.data)
}

const UpdateSubscription = async (e) => {
  e.preventDefault();
  try {
    let selectedQuantity;
    let days = [];
    let qty = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Calculate the difference in days
    // Determine the selected quantity based on the frequency
    
        // Handle subscription scenarios (everyday, interval, custom)
        switch (frequency) {
            case "everyday":
              selectedQuantity = quantity;
              days = "Everyday";
              qty = quantity;
              break;
            case "interval":
              if (intervalValue === "custome") {
                // Handle custom interval case
                const customIntervalDays = parseInt(customInterval);
                if (!isNaN(customIntervalDays) && customIntervalDays > 0) {
                  const numIntervals = Math.floor(diffDays / customIntervalDays);
                  days =  `Every ${customIntervalDays} days`;
                  qty =  intervalQuantities;
                  selectedQuantity = intervalQuantities * numIntervals;
                }
              } else {
                // Handle other interval cases (e.g., "Every 2 days", "Every 3 days", etc.)
                const intervalDay = parseInt(intervalValue);
                if (!isNaN(intervalDay) && intervalDay > 0) {
                  const numIntervals = Math.floor(diffDays / intervalDay);
                  days =  `Every ${intervalDay} days`;
                  qty =  intervalQuantities;
                  selectedQuantity = qty;
                }
              }
              break;
            case "custom":
              // Sum up the quantities for all days in customQuantities
              days = Object.keys(customQuantities).map(day => capitalizeFirstLetter(day));
              qty = Object.values(customQuantities);
              selectedQuantity = qty.reduce((acc, val) => acc + val, 0);
              break;
            default:
              // Default case if somehow an unexpected frequency is received
              selectedQuantity = 0;
              break;
        }
  

    const response = await axios.put('https://desigro.languagenectar.in/api/v1/customer/order/update-subscription', {
      payment_method: "Subscription",
      subscription_type: frequency,
      qty: JSON.stringify(qty),
      days: JSON.stringify(days),
      mode: "debit",
      subscription_date: startDate,
      subscription_end: endDate,
      id: selectedSubscription.id,
      alt_qty: altquantity,
      date_on_demand: ""
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
};

  const handleCloseModal = () => {
    setSelectedSubscription(null);
  };
  const handleVacationClick = (orderId) => {
    setShowPopup(true);
    setSelectedOrderId(orderId);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleStartDateChange = () => {
   
    if(endvacationDate < vacationDate){
      setEndvacationDate("")
    }
  };

  const handleApplyVacation = async () => {
    // If end date is not selected, set it to one month after start date
    if (!endvacationDate) {
      const oneMonthLater = new Date(vacationDate);
     const onemonthforward = oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
     console.log(onemonthforward)
      setEndvacationDate(oneMonthLater);
    }
  
    try {
      // Make API call to add vacation
      const response = await axios.post(
        'https://desigro.languagenectar.in/api/v1/customer/add-vacation',
        {
          vacation_start: vacationDate,
          vacation_end: endvacationDate,
          order_id: selectedorderId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
  
      // Handle response
      if (response.data.status === true) {
        console.log(response)
      } else {
        console.error('Failed to add vacation:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching API response:', error);
    }
  
    // Close the vacation popup
    handleClosePopup();
  };
  

  
  const formatDate = (dateString) => {
    // Use Date object to format the date
    const date = new Date(dateString);
    // Customize the date formatting as needed
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    return formattedDate;
  };


  return (
    <div>
      <Navbar/>
      <section >
    <div className="subscription-list">
  <h2>Subscription Products</h2>
  <div className="products-subslist">
    {Subscribe.map((product) => (
      <div key={product.id} className="product-subslist">
        <div className="product-subslist1">
        <img src={`https://desigro.languagenectar.in/storage/app/public/product/${product.image}`} alt={product.name} className="product-subslist-image" />
        <div class="checkbox-apple">
              <input class="yep" id="check-apple" type="checkbox"checked={product.is_active}  />
              <label for="check-apple"></label>
            </div>
            <div className="product-subslist-text">
        <h3 className="product-subslist-name">{product.name}</h3>
        <p className="price-subslist">₹{product.price}</p>
        <p className="text-capitalize ">{product.subscription_type}</p>
        <p>{product.order_id}</p>
        <p className="description-subslist">Start Date:-{formatDate(product.subscription_date)}</p>
        <p className="description-subslist">End Date:-{formatDate(product.subscription_end)}</p>
            </div>
        </div>
        <div className="subscribe-button-sublist">
          <button className="subscribe-button-1" onClick={()=>DeleteSubscription(product.id)}>Delete</button>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" className="subscribe-button-2"
             onClick={() => handleEditSubscription(product)}
          >Edit</button>
          <button className="subscribe-button-3" onClick={()=>handleVacationClick(product.order_id)} >Vacation</button>
        </div>
        {showPopup && (
        <div className="vacation-popup">
          <h2>Select vacation dates:</h2>
          <div>
            <label>Start Date:</label>
            <input type="date" value={vacationDate} onChange={(e)=>setvacationDate(e.target.value)} onBlur={handleStartDateChange} />
          </div>
          <div>
            <label>End Date:</label>
            <input type="date" value={endvacationDate} onChange={(e)=>setEndvacationDate(e.target.value)} min={vacationDate} />
          </div>
          <div className="vacation-button-div">
          <button className="vacation-button" onClick={handleClosePopup}>Cancel</button>
          <button className="vacation-button" onClick={handleApplyVacation}>Apply</button>
          </div>
        </div>
      )}
      </div>
    ))}
  </div>
</div>
    </section>
    <section>
      <div>



<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <form>
      
                        <>
                          <label htmlFor="">Start date</label>
                          <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setstartDate(e.target.value)}
                            onBlur={UpdateDate}
                          />
                          <label htmlFor="">End date</label>
                          <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setendDate(e.target.value)}
                            min={startDate}
                            placeholder="Optional"
                          />
                        </>
                      
                      
                        <div className="form-group">
                          <label htmlFor="frequency">Frequency</label>
                          <select
                            className="form-select"
                            name="frequency"
                            id="frequency"
                            value={frequency}
                            onChange={(e) => setFrequency(e.target.value)}
                          >
                            <option value="everyday">Everyday</option>
                            <option value="interval">On Interval</option>
                            <option value="custom">Custom</option>
                          </select>
                        </div>
                                            

                      { frequency === "everyday" && (
                         <>
                         Day 1 : <QuantitySelector
                            quantity={quantity}
                            onDecrease={(e) =>
                              HandleDecrease("everyday", null, e)
                            }
                            onIncrease={(e) =>
                              HandleIncrease("everyday", null, e)
                            }
                          />
                         Day 2 : <QuantitySelector
                            quantity={altquantity}
                            onDecrease={(e) =>
                              HandleAltDecrease(e)
                            }
                            onIncrease={(e) =>
                              HandleAltIncrease(e)
                            }
                            />
                            </>
                      )}

                      {frequency === "interval" && (
                        <div>
                          <label htmlFor="interval">Select Interval</label>
                          <select
    className="form-select"
    id="interval"
    name="interval"
    value={intervalValue}
    onChange={handleIntervalChange}
>
    <option value="2">Every 2 days</option>
    <option value="3">Every 3 days</option>
    <option value="4">Every 4 days</option>
    <option value="5">Every 5 days</option>
    <option value="custome">Every nth Days</option>
</select>

                          <br />

                          {intervalValue >= 2 && intervalValue <= 5 && (
                            <QuantitySelector
                              quantity={intervalQuantities}
                              onDecrease={(e) =>
                                HandleDecrease("interval", null, e)
                              }
                              onIncrease={(e) =>
                                HandleIncrease("interval", null, e)
                              }
                            />
                          )}

                          {intervalValue === "custome" && (
                            <div>
                              <label htmlFor="customInterval">
                                Custom Interval:
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ margin: "0 5px" }}>Every</span>
                                <input
                                  type="text"
                                  id="customIntervalLeft"
                                  style={{ marginRight: "5px", width: "50px" }}
                                  value={customInterval.left}
                                  onChange={(e) =>
                                    setCustomInterval(
                                      e.target.value)
                                  }
                                />
                                <span style={{ margin: "0 5px" }}>Days</span>
                              </div>
                              <label htmlFor="customQuantity">
                                Custom Quantity:
                              </label>
                              <QuantitySelector
                                quantity={intervalQuantities}
                                onDecrease={(e) =>
                                  HandleDecrease("interval", null, e)
                                }
                                onIncrease={(e) =>
                                  HandleIncrease("interval", null, e)
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}

                      {frequency === "custom" && (
                        <div>
                          {Object.keys(customQuantities).map((day) => (
                            <div key={day}>
                              <label htmlFor={day}>
                                {capitalizeFirstLetter(day)}:
                              </label>
                              <QuantitySelector
                                quantity={customQuantities[day]}
                                onDecrease={(e) =>
                                  HandleDecrease("custom", day, e)
                                }
                                onIncrease={(e) =>
                                  HandleIncrease("custom", day, e)
                                }
                              />
                            </div>
                          ))}
                        </div>
                      )}
                       <button
                        onClick={UpdateSubscription}
                        className="button-product-details"
                      >
                        Subscribe
                      </button>
       </form>
      </div>
      <div class="modal-footer">
      {/* <button type="button" class="btn-close" aria-label="Close"></button> */}

        {/* <button type="button" class="btn btn-primary">Save changes</button> */}
      </div>
    </div>
  </div>
</div>
      </div>
    </section>
    <Footers/>
</div>
  );
};

export default SubscriptionList;
