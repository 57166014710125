import React, { useEffect, useState } from 'react';
import './updateprofile.css';
// import placeholderImage from './placeholder.png'; // Import your placeholder image
import placeholderimage from './userprofile.jpeg'
import axios from 'axios';
import Apiservice from '../../../../Api/ApiController';
import Swal from 'sweetalert2';
import { NavLink, useNavigate } from 'react-router-dom';
import Navbar from '../../../navbar/NavIgationBar';
import Footers from '../../../footer/Footers';
export default function UpdateProfile() {
  const [profilePic, setProfilePic] = useState(null);
  const [firstname,setfirstname] = useState('');
  const [lastname,setlastname] = useState('');
  const [email,setemail] = useState('');
  const token = sessionStorage.getItem('authtoken');
  const navigate =  useNavigate()
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const AddProfile = async (e)=>{
    e.preventDefault()
    try{
    
      const response =  await Apiservice.updateProfile({
         f_name:firstname,
        l_name:lastname,
        email:email,
        image:profilePic
      })
      if(response.data.status === true){
        Swal.fire({
          icon:'success',
          text:'Profile updated Sccessfully'
        })
        navigate('/profile')
      }
    }catch(error){
      console.log(error)
    }
  }
  useEffect(()=>{
    Apiservice.getProfile().then((response)=>{
      if(response.data.status === true){
              console.log(response.data.data)
              setfirstname(response.data.data.f_name)
              setlastname(response.data.data.l_name)
              setemail(response.data.data.email)
      }else{

      }
    }).catch((err)=>console.log(err))
  },[])
  return (
    <>
    <Navbar/>
    <div className="form-container1">
    <section>
      <form className="form-addprofile1" onSubmit={AddProfile} >
     
        <p className="title-addprofile1">Update Details</p>

        <div className="flex-addprofile1">
          <label>
            <input required placeholder="Enter Firstname" value={firstname} onChange={(e)=>setfirstname(e.target.value)} type="text"  className="input-addprofile1" />
            {/* <span>Firstname</span> */}
          </label>
  
          <label>
            <input required placeholder="Enter Lastname" value={lastname} onChange={(e)=>setlastname(e.target.value)} type="text" className="input-addprofile1" />
            {/* <span className="span-addprofile">Lastname</span> */}
          </label>
        </div>
  
        <label>
          <input required placeholder="Enter Email" value={email} onChange={(e)=>setemail(e.target.value)} type="email" className="input-addprofile1" />
          {/* <span>Email</span> */}
        </label>
  
        
        {/* <label>
          <input  placeholder="Enter Referal Code(Optional)" type="text" className="input-addprofile" />
          
        </label> */}
            <div className='submit-updateprofile'>
        <button type='submit' className="submit-updateprofile-btn">Submit</button>
            </div>
      </form>
    
    
    </section>
  </div>
  <Footers/>
    </>
  
  );
}
