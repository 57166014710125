import React from "react";
import "./about.css";
import NavIgationBar from "../../navbar/NavIgationBar";
import Footers from "../../footer/Footers";

export default function About() {
  return (
    <div>
      <NavIgationBar />
      <div>
        <div className="about-banner">
          <div className="about-banner-image">
            <img src={process.env.PUBLIC_URL + "/assets/img/farmer.jpg"} alt="" />
          </div>
          <div className="about-banner-text">
            <h1>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut
              impedit similique at minima aperiam vero nulla quae excepturi.
              Molestiae, odio.
            </h1>
          </div>
        </div>
        {/* ============================================================================ */}
        <div className="about-section-1">
          <div className="about-section-image">
            <img src={process.env.PUBLIC_URL + "/assets/img/7.jpg"} alt="" />
          </div>
          <div className="about-section-text">
            <div className="about-subsection-text1">
              <h3>Welcome to Farmer's Empowerment Group</h3>
              <hr />
            </div>
            <div className="about-subsection-text2">
              <p>
                We at Farmer's Empowerment Group committed to build a healthy
                community by providing pure produce to our Esteemed Customers
                without any preservatives.
              </p>
              <p>
                Empower our farmer friends by offering fair price for their
                produce & job creation in villages. Our first initiative under
                Farmer's Empowerment Group is The Desi Milk™
              </p>
              <p>
                The Desi Milk™ is a produce of carefully chosen Indigenous &
                Healthy Dairy Livestock i.e. Gir Cows, Buffalos & Goats. To
                ensure 100% authenticity, we have robust quality control system.
                The milk is sourced directly from ours and farmer friends' dairy
                farm. No middleman right from milk souring to distribution.
              </p>
            </div>
          </div>
        </div>
        {/* ================================================================================== */}

        <div className="about-section-2">
          <div className="about-section-2-text">
            <h2>GIR COW'S A2 MILK & GHEE</h2>
              <hr />
            <div className="about-subsection-2-text1">
              <h5>Daily Consumption Health Benefits</h5>
              <p>
              Gir cow's A2 Milk & Ghee - Daily Consumption Health Benefits
              </p>
              <p>Gir cow's A2 Milk is considered as healthy as a mother's milk</p>
              <p>It is easier to digest and healthier than other milk (A1 Milk) available in the market</p>
              <p>Helps to build immunity & improves metabolism</p>
              <p>Helps Prevent Type 1 Diabetes, Heart-related disorder & arthritis</p>
              <p>Helps to regulate Blood Pressure & maintain Cholesterol level</p>
              <p>Helps in mental & physical development of infants & children</p>
              <p>Provides adequate Calcium, Vitamin B12 & Omega 3 fatty acids</p>
              <p>Helps to reduce weight, Keep body energetic and healthy</p>
            </div>
            <div className="about-subsection-2-text2"></div>
          </div>
          <div className="about-section-2-image">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/hero_1.jpg"}
              alt=""
            />
          </div>
        </div>
        {/* =============================================================================================== */}

        <div className="about-section-3">
          <div className="about-subsection-3">
            <h2>The DesiGro Farms</h2>
          </div>
          <div className="about-subsection-3-main">
            <div className="about-subsection-3-part">
              <div className="about-subsection-3-part1">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/drone.svg"}
                  alt=""
                />
              </div>
              <div className="about-subsection-3-part2">
                <h3>Use Of Drones</h3>
                <p>Collection of crop data for advanced analytics</p>
              </div>
            </div>

            <div className="about-subsection-3-part">
              <div className="about-subsection-3-part1">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/drone.svg"}
                  alt=""
                />
              </div>
              <div className="about-subsection-3-part2">
                <h3>Use Of Drones</h3>
                <p>Collection of crop data for advanced analytics</p>
              </div>
            </div>

            <div className="about-subsection-3-part">
              <div className="about-subsection-3-part1">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/drone.svg"}
                  alt=""
                />
              </div>
              <div className="about-subsection-3-part2">
                <h3>Use Of Drones</h3>
                <p>Collection of crop data for advanced analytics</p>
              </div>
            </div>

            <div className="about-subsection-3-part">
              <div className="about-subsection-3-part1">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/drone.svg"}
                  alt=""
                />
              </div>
              <div className="about-subsection-3-part2">
                <h3>Use Of Drones</h3>
                <p>Collection of crop data for advanced analytics</p>
              </div>
            </div>

            <div className="about-subsection-3-part">
              <div className="about-subsection-3-part1">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/drone.svg"}
                  alt=""
                />
              </div>
              <div className="about-subsection-3-part2">
                <h3>Use Of Drones</h3>
                <p>Collection of crop data for advanced analytics</p>
              </div>
            </div>

            <div className="about-subsection-3-part">
              <div className="about-subsection-3-part1">
                <img
                  src={process.env.PUBLIC_URL + "/assets/img/drone.svg"}
                  alt=""
                />
              </div>
              <div className="about-subsection-3-part2">
                <h3>Use Of Drones</h3>
                <p>Collection of crop data for advanced analytics</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footers />
    </div>
  );
}
