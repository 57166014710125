// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import Navbar from "../../navbar/NavIgationBar";
// import Footers from "../../footer/Footers";
// import './searchproduct.css'
// const SearchPage = () => {
//   const [products, setProducts] = useState([]);
//   const location = useLocation();
// const navigate = useNavigate()
//   useEffect(() => {
//     const searchQuery = new URLSearchParams(location.search).get("name");
//     if (searchQuery) {
//       fetchProducts(searchQuery);
//     }
//   }, [location.search]);

//   const HandleProductClick = (productId) => {
//     navigate(`/product-details/${productId}`);
//   };
//   const HandleSubscribeProduct = (productId) =>{
//     navigate(`/subscribeproduct-details/${productId}`);
//   }
//   const fetchProducts = async (searchQuery) => {
//     try {
//       const response = await axios.get(
//         `https://desigro.languagenectar.in/api/v1/services/search`,
//         {
//           headers: {
//             ZoneId: 1
//           },
//           params: {
//             name: searchQuery
//           }
//         }
//       );
//       console.log(response.data.data.products);
//       setProducts(response.data.data.products); // Set the products state with the fetched products
//     } catch (error) {
//       console.error("Error fetching products:", error);
//     }
//   };

//   return (
//     <div>
//         <Navbar/>
//         <div id="SearchProduct">

     
//       <div className="products-card-list">
//         {products.map((product) => (
//             <div className="card" style={{ width: "18rem", padding: "10px" }} key={product.id}>
//             <div className="product-card-image">
//               <img
//                 src={`https://desigro.languagenectar.in/storage/app/public/product/${product.image}`}
//                 className="card-img-top"
//                 alt="..."
//               />
//             </div>
//             <div className="card-body">
//               <h5 className="card-title">{product.name}</h5>
//             </div>
//             <ul className="list-group list-group-flush">
//               <div className="product-price">
//                 <s>₹{product.price}</s>
//                 <p>From</p>
//                 <p>₹{product.price - product.discounted_price}</p>
//               </div>
//             </ul>
//             <div className="card-body">
//               <div className="product-card-btns">
//                 <button onClick={()=>HandleProductClick(product.id)} className="btn-product-card">Buy Once</button>
              
//                 {product.category_ids && product.category_ids.is_subscribe === 1 && (
//                   <button onClick={()=>HandleSubscribeProduct(product.id)} className="btn-product-card">Subscribe</button>
//                   )}
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//     <Footers/>
//         </div>
//   );
// };

// export default SearchPage;


import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../../navbar/NavIgationBar";
import Footers from "../../footer/Footers";
import "./searchproduct.css"; // Import your CSS file
import { FadeLoader } from "react-spinners";
import Apiservice from "../../../Api/ApiController";

const SearchPage = () => {
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const searchQuery = new URLSearchParams(location.search).get("name");
    if (searchQuery) {
      fetchProducts(searchQuery);
    }
  }, [location.search]);

  const HandleProductClick = (productId) => {
    navigate(`/product-details/${productId}`);
  };

  const HandleSubscribeProduct = (productId) => {
    navigate(`/subscribeproduct-details/${productId}`);
  };

  const fetchProducts = async (searchQuery) => {
    try {
      const response = await axios.get(
        `https://desigro.languagenectar.in/api/v1/services/search`,
        {
          headers: {
            ZoneId: 1,
          },
          params: {
            name: searchQuery,
          },
        }
      );
  
      
      setProducts(response.data.data.products);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div id="SearchProduct">
      {loading ? (
        <div className="loader-container">
          <div className="loader">
            <FadeLoader color="#269a36" />
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h2>Product List</h2>
          {products.length > 0 ? (
            <div className="products-list">
              {products.map((product) => (
                <div className="product-card" key={product.id}>
                  <div className="product-image">
                    <img
                      src={`https://desigro.languagenectar.in/storage/app/public/product/${product.image}`}
                      alt="Product"
                    />
                  </div>
                  <div className="product-details">
                    <h3 className="product-name">{product.name}</h3>
                    <p className="product-price">Price: ₹{product.price}</p>
                  </div>
                  <div className="product-actions">
                    <button
                      className="view-details-btn"
                      onClick={() => HandleProductClick(product.id)}
                    >
                      Buy Once
                    </button>
                    {product.category_ids && product.category_ids.is_subscribe === 1 && (
              <button  onClick={()=>HandleSubscribeProduct(product.id)} className="subscribe-btn">Subscribe</button>
            )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No products available for the selected category.</p>
          )}
        </div>
      )}
      </div>
      <Footers />
    </div>
  );
};

export default SearchPage;
