// Loader.js
import React from 'react';
import { PuffLoader } from 'react-spinners';

const Loader = ({ loading }) => {
  return (
    <div className={`loader-container ${loading ? 'visible' : 'hidden'}`}>
      <div className="loader">
        <PuffLoader loading={loading} size={60} color="#00000" />
      </div>
    </div>
  );
};

export default Loader;
