import React, { useEffect, useState } from "react";
import NavIgationBar from "../../../navbar/NavIgationBar";
import Footers from "../../../footer/Footers";
// import "./profile.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Apiservice from "../../../../Api/ApiController";

export default function GetProfile() {
  const [profile, setProfile] = useState([]);
  const token = sessionStorage.getItem("authtoken");
  const navigate = useNavigate();
  const [OrderList, setOrderList] = useState([]);

  useEffect(() => {
    const getProfileAPI = async () => {
      try {
        const response = await Apiservice.getProfile();
        if (response.data.status === true) {
          console.log(response.data);
          setProfile(response.data);
        }
      } catch (error) {
        console.error("Error fetching profile:", error.message);
      }
    };

    if (token) {
      getProfileAPI();
    } else {
    }
  }, [token]);
  const handleLogout = () => {
    if (token) {
      Swal.fire({
        title: "Logout",
        text: "Are you sure you want to logout?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, logout!",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          sessionStorage.removeItem("authtoken");
          navigate("/");
          window.location.reload();
          Swal.fire(
            "Logged Out!",
            "You have been logged out successfully.",
            "success"
          );
        }
      });
    } else {
      Swal.fire({
        title: "Login",
        text: "Hmm, it seems like you're not logged in yet. To access this feature, please log in or create an account. We're excited to have you on board!",
        icon: "warning",
        showCancelButton: true,
      });
    }
  };

  const fetchorderlist = async () => {
    try {
      const response = await Apiservice.orderList({
        limit: 10,
        offset: 0,
      });
      if (response.data.status === true) {
        setOrderList(response.data.data.orders);
        console.log(response.data);
      }
    } catch (error) {
      console.log("Error in fetching Api", error);
    }
  };
  useEffect(() => {
    fetchorderlist();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 4;
  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  // Filter the orderList to get orders for the current page where is_subscribe is 0
  const ordersOnCurrentPage = OrderList.filter(
    (order) => order.is_subscribe === 0
  ).slice(startIndex, endIndex);

  // Calculate the total number of pages based on filtered orders
  const totalPages = Math.ceil(
    OrderList.filter((order) => order.is_subscribe === 0).length /
      ITEMS_PER_PAGE
  );

  // Function to handle page changes
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    // Use Date object to format the date
    const date = new Date(dateString);
    // Customize the date formatting as needed
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return formattedDate;
  };

  const orderdetials = (orderId) => {
    navigate(`/orderdetails/${orderId}`);
  };
  return (
    <div>
      <NavIgationBar />
      <div className="container mx-auto px-4">
        {/* Account Details Section */}
        <section className="mt-6 px-10 flex justify-center items-center mx-auto my-10">
          <div className="w-full max-w-4xl bg-white ">
            <section className="mt-6 flex flex-col sm:flex-row justify-between rounded-lg items-center p-2  space-y-6 sm:space-y-0  bg-[#2f8814] sm:space-x-8">
              {/* username with update button */}
              <div className="my-4">
                {profile.data ? (
                  <>
                    <p className="text-sm md:text-3xl text-white mb-4">
                      Hi, {profile.data.f_name || "UserName"}
                    </p>
                    <Link
                      to="/updateprofile"
                      className="text-black  no-underline text-center bg-[#dcfed1] py-1 px-2 md:px-4 rounded-md text-[2.4vw] md:text-lg font-semibold "
                    >
                      Update Profile
                    </Link>
                  </>
                ) : (
                  <Link
                    to="/signup"
                    className="text-black  no-underline text-center bg-[#dcfed1] py-1 px-2 md:px-4 rounded-md text-[2.4vw] md:text-lg font-semibold "
                  >
                    Please Login
                  </Link>
                )}
              </div>

              <div className="my-4">
                {/* Wallet Display */}
                <div className="p-2 md:p-4 bg-[#dcfed1] text-black rounded-lg  ">
                  <Link
                    to="/wallet"
                    className="text-black text-xl  hover:text-gray-300 no-underline flex items-center"
                  >
                    <i className="bx bx-wallet-alt mx-2"></i>
                    {profile.data ? (
                      <div>
                        <h1 className="text-lg font-semibold">
                          Available balance ₹ {profile.data.wallet_balance}
                        </h1>
                      </div>
                    ) : (
                      <span className="text-sm text-gray-400">
                        Please recharge your wallet
                      </span>
                    )}
                  </Link>
                </div>
              </div>
            </section>

            <h1 className="text-xl md:text-2xl mt-2 font-semibold text-center mb-6 text-gray-800">
              Account Details
            </h1>

            {/* Links to other sections */}
            <div className="space-y-4">
              <Link
                to="/addresses"
                className="block text-xl text-gray-700 hover:text-blue-600 no-underline"
              >
                View Addresses
              </Link>
              <Link
                to="/subscription-list"
                className="block text-xl text-gray-700 hover:text-blue-600 no-underline"
              >
                View Subscription
              </Link>
              <Link
                to="/vacation"
                className="block text-xl text-gray-700 hover:text-blue-600 no-underline"
              >
                View Vacation
              </Link>
              <Link
                to="/orderhistory"
                className="block text-xl text-gray-700 hover:text-blue-600 no-underline"
              >
                View Order History
              </Link>
              <Link
                to="/deliveryhistory"
                className="block text-xl text-gray-700 hover:text-blue-600 no-underline"
              >
                Delivery History
              </Link>
              <Link
                onClick={()=>{
                  handleLogout(); 
                }}
                className="block text-xl text-gray-700 hover:text-green-600 no-underline bg-[#dcfed1] w-fit  px-2 py-1 rounded"
              >
                Logout
              </Link>
            </div>
          </div>
        </section>
      </div>

      <Footers />
    </div>
  );
}
