import React, { useEffect, useState } from "react";
import Navbar from "../../navbar/NavIgationBar";
import Footers from "../../footer/Footers";
import './privacypolicy.css';
import axios from "axios";

export default function PrivacyPolicy() {
  const [privacy, setPrivacy] = useState(null);

  const fetchPrivacyPolicy = async () => {
    try {
      const response = await axios.get('https://desigro.languagenectar.in/api/v1/config');

      if (response.data.status === true) {
        setPrivacy(response.data.data);
        console.log(response.data.data);
      } else {
        console.log('Error in fetching');
      }
    } catch (error) {
      console.error('Error fetching privacy policy:', error.message);
    }
  };

  useEffect(() => {
    fetchPrivacyPolicy();
  }, []);

  return (
    <div>
      <div>
        <Navbar />
        <section>
          <div className="privacy-head">
            <h3>Privacy Policy</h3>
            <hr className="hrprivacy" />
          </div>
          <div className="privacy-policy">
          <div className="privacy-policy" dangerouslySetInnerHTML={{ __html: privacy && privacy.privacy_policy }} />

          </div>
          <div></div>
        </section>
        <Footers />
      </div>
    </div>
  );
}
