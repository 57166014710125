import React from 'react'
import gsap from 'gsap';
import { useEffect ,useRef} from 'react';

const Alertt = () => {
  const alertRef=useRef(null)
 useEffect(() => {
   // GSAP animation
   gsap.fromTo(
     alertRef.current,
     {  opacity: 0.1 },
     {
       opacity: 1,
       duration: 1.5,
       ease: "power2.inOut",
       repeat: -1,
       }
   );
 }, []);
  return (
    <div>
      <h1
        ref={alertRef}
        className="bg-red-400 rounded-2xl px-4 py-2 text-center w-fit mx-auto text-white text-xs md:text-lg lg:text-xl mb-6 shadow-lg"
      >
        🔔 Orders placed before 11:59 PM will be delivered the next day!
      </h1>
    </div>
  );
}

export default Alertt
